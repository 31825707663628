.v-scroll {
  display: block;
  -ms-overflow-style: scrollbar;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-color: $color-tuic-blue-info $color-tuic-bg;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-tuic-bg;
    border-radius: 25px;
    padding: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-tuic-blue-info;

    border: 2px solid $color-tuic-bg;
    border-radius: 25px;
  }
}
