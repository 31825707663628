.inclusive-excursion {
  .booking-options {
    .multi-select {
      .title {
        display: none;
      }

      .option {
        position: relative;
        padding: 16px 8px;
        border-bottom: 1px solid $color-tuic-border-dark;

        &:nth-child(2) {
          border-top: 1px solid $color-tuic-border-dark;
        }

        .label {
          @include font(13px, 1);
          font-weight: bold;
          margin-bottom: 16px;
        }

        .select {
          margin-bottom: 16px;
        }

        .checkbox {
          .form-checkbox-group__item {
            background: transparent;

            .form-checkbox-group__item__label {
              position: absolute;
              top: 3px;
              right: 20px;
              width: 104px;
              white-space: nowrap;
              @include font(12px, 1.5);
            }

            .form-checkbox-group__item__input,
            .form-checkbox-group__item__indicator {
              position: absolute;
              top: 0;
              right: 0;
            }
          }
        }
      }
    }

    .messages {
      padding: 16px 8px;
    }

    .booking-confirmation__buttons {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
}

@media #{$screen-tablet-desktop} {
  .inclusive-excursion {
    .booking-options {
      .multi-select {
        &.checkbox {
          .form-checkbox-group__item {
            .form-checkbox-group__item__label {
              @include font(12px, 1.5);
            }
          }
        }
      }
    }
  }

}

@media #{$screen-desktop} {
  .inclusive-excursion {
    .booking-options {
      .multi-select {
        .title {
          display: block;
          @include font(15px, 1.5);
          font-weight: bold;
          padding: 12px 8px;
          border-bottom: 1px solid $color-tuic-border-dark;
          margin-bottom: 16px;
        }

        .option {
          border: 0;
          padding: 0 8px;
          height: 36px;
          margin-bottom: 4px;

          &.ignored {
            height: auto;
            padding-top: 10px;
            padding-bottom: 11px;

            .hint {
              padding-left: 8px;
            }
          }

          &:nth-child(2) {
            border: 0;
          }

          .item {
            display: inline-block;
            margin: 0;

            &.label {
              width: 170px;
              margin-right: 12px;
              vertical-align: middle;
            }

            &.select {
              width: 252px;
              margin-right: 8px;

              .form-dropdown {
                margin-bottom: 0;
              }
            }

            &.checkbox {
              width: 150px;
              position: absolute;
              top: 0;
              right: 8px;

              .form-checkbox-group__item {
                margin-bottom: 0;

                .form-checkbox-group__item__label {
                  font-size: 13px;
                  right: 36px;
                  padding: 6px 0;
                }

                .form-checkbox-group__item__indicator {
                  line-height: 36px;
                  min-height: 36px;
                  min-width: 36px;
                }
              }
            }
          }
        }
      }

      .messages {
        border-top: 1px solid $color-tuic-border-dark;
        margin-top: 12px;
        padding-top: 16px;
      }
    }
  }
}
