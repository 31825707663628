$base-padding: 12px;
$price-font-size: 21px;
$price-sub-font-size: 15px;

.catalog-tile {
    background: $color-tuic-blue-white;
    display: flex;
    margin: 0 $base-padding $base-padding 0;
    min-height: 71px;
    padding: $base-padding;
    position: relative;

    &.highlight {
        border: 2px solid $color-tuic-blue-dark;
        min-height: 69px;
    }

    .desktop {
        display: none;
    }

    &:hover {
        background-color: $color-tuic-blue-light-hover;
        color: $color-white;

      &.large .catalog-tile__content > div:first-child::after {
        background: linear-gradient(180deg, transparent 75%, #72cae8);
      }
    }

    &.no-hover:hover {
        background-color: $color-tuic-blue-white;
        color: $color-tuic-blue-dark;
    }

    &.disabled {
        @extend %disabled;
        background: $color-tuic-blue-light;
        color: $color-tuic-blue-white;
    }

    .price,
    .price-inclusive {
        margin-bottom: 0;
    }

    // Fix for possbile text overlapping
    @media screen and (max-width: 290px) {
        &.has-price {
            padding-bottom: $price-font-size + $base-padding;
        }
    }

    &__content {
        h3 {
            @include font(15px, 1.5);
            font-size: 15px;
            max-height: 15px * 1.5 * 3;
            overflow: hidden;
        }

        &__bottom {
            position: relative;
        }

        > div:first-child {
            margin-bottom: 22px;
        }
    }

    &__overline {
      margin-bottom: 17px;
      @include font(16px, 1.5);

      .icon {
        float: left;
        margin-right: 10px;
      }
    }

    &__image {
        float: left;
        margin-right: $base-padding;
        margin-bottom: $base-padding;
        min-width: 71px;
        width: 71px;

        @media screen and (max-width: 360px) {
            .picture {
                width: 71px;
            }

            &.large-tile {
              .picture {
                width: 272px;
              }
            }
        }
    }

    &__footer {
        .price,
        .price-inclusive {
            bottom: $base-padding;
            position: absolute;
            right: $base-padding;
        }

        .price-inclusive {
            font-family: SourceSansPro;
            font-size: 21px;
            line-height: 1.2;
        }

        // Just a small visual fix
        .rating {
            display: block;
            margin-top: 2px;
        }
    }

    .date-discription {
        margin-top: -12px;
    }

    @media #{$max-screen-tablet-desktop} {
        &.has-badge {
            margin-top: 30px;
        }
    }
}

@media #{$screen-tablet-desktop} {
    .catalog-tile {
        display: block;
        float: left;
        min-height: 316px;
        width: 270px;

        &.highlight {
            border: 2px solid $color-tuic-blue-dark;
            min-height: 314px;
            width: 268px;
        }

        .desktop {
            display: inline;
        }

        &__image {
            float: none;
            height: 153px;
            overflow: hidden;
            width: 100%;
        }

        &__content {
            display: flex;
            flex-flow: column;
            height: 110px;
            justify-content: space-between;

            .has-price & {
                height: 130px + $price-font-size;
            }

            h3 {
                @include font(17px, 1.3);
                max-height: 17px * 1.3 * 3;
            }

            &__bottom {
                bottom: -15px;
                position: absolute;
            }
        }

        &__footer {
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .price-inclusive {
                font-size: 30px;
            }

            &__content {
                bottom: $base-padding;
                left: $base-padding;
                position: absolute;
            }
        }

        .date-discription {
            margin-top: -72px;
        }
    }
}

.large,
.napp {
    .catalog-tile {
        display: block;
        min-height: 316px;
        margin: 12px auto;
        width: 92%;
        max-width: 360px;
        -webkit-tap-highlight-color: transparent;

        &.highlight {
            border: 2px solid $color-tuic-blue-dark;
            min-height: 314px;
            width: 268px;
        }

        .desktop {
            display: inline;
        }

        &__image {
            float: none;
            height: 153px;
            overflow: hidden;
            width: 100%;
            max-width: 360px;
        }

        &__content {
            display: flex;
            flex-flow: column;
            height: 110px;
            justify-content: space-between;

            .has-price & {
                height: 130px + $price-font-size;
            }

            h3 {
                @include font(17px, 1.3);
                max-height: 17px * 1.3 * 3;
            }

            &__bottom {
                bottom: -15px;
                position: absolute;
            }
        }

        &__footer {
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .price-inclusive {
                font-size: 30px;
            }

            &__content {
                bottom: $base-padding;
                left: $base-padding;
                position: absolute;
            }
        }

        .date-discription {
            margin-top: -72px;
        }
    }
}

.napp {
  .catalog-tile {
    @media #{$screen-tablet-desktop} { // min-width: 644px
      float: left;
      margin: 0 12px 12px 0;
      width: 270px;
    }
    @media #{$max-screen-tablet-desktop} {
      &.has-badge {
        margin-top: 30px;
      }
    }
  }
}

.large {
  .catalog-tile {

    &__content {
      height: unset;
    }

    &__image {
      &.large-tile {
        position: relative;
        height: auto;
        max-width: 360px;
        margin-right: 12px;
      }
    }

    @media #{$max-screen-tablet-desktop} {
      margin-top: 24px;
      margin-bottom: 44px;
    }

    @media #{$screen-desktop} { // min-width: 644px
      min-height: 283px;
      width: 100%;
      max-width: 100%;
      padding: 12px;

      &__content {
        height: auto;
        padding: 12px;

        > div:first-child {
          margin-bottom: 0;
          max-height: 260px;
          overflow: hidden;

          &::after {
            content: ' ';
            position: absolute;
            height: 100%;
            width: calc(100% - 600px);
            bottom: 0;
            right: 0;
            background: linear-gradient(180deg, transparent 75%, #ceebf4);
          }
        }
      }

      &__image {
        &.large-tile {
          position: relative;
          float: left;
          height: auto;
          max-height: 283px;
          width: 580px;
          max-width: unset;
          margin-bottom: 0;
          margin-right: 12px;
          overflow: hidden;
        }
      }
    }
  }
}
