.morebox {
    display: block;

    &__content {
        display: table-cell;
        vertical-align: top;
        width: 100%;

        > :last-child {
            margin-bottom: 0;
        }

        &__more-link,
        &__less-link {
            text-decoration: underline;
        }
    }
    a {
      text-decoration: underline;
    }
}
