.not-found {
    .divider {
        height: 29px;
    }

    .button {
        width: 153px;
        text-align: center;
    }
}

