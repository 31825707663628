.main-app {
    margin: 0 auto;
}

.page {
    box-sizing: border-box;
    padding: 0 12px;
}

@media #{$screen-tablet-desktop} {
    .main-app {
        max-width: $max-width-tablet;
    }
}

@media #{$screen-desktop} {
    .main-app {
        max-width: $max-width-desktop;
    }
}
