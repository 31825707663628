.checkin-overview {
    .faq {
        padding-top: 12px;
    
        @media #{$screen-desktop} {
            padding-top: 0;
        }

        .button {
            width: 100%;
            padding: 7px 10px;
            text-align: center;

            @media #{$screen-tablet-desktop} {
                width: initial;
            }
        }
    }

    .content-box {
        padding: 14px 12px 12px 12px;
        @media #{$screen-tablet-desktop} {
            padding: 20px;
        }
    }

    .header-checkin {
        margin-top: 24px;
    }
}

.form-checkin-arrivalat {
    .form-checkin-arrivalat__block {
        padding-bottom: 36px;
    }
    .form-checkin-timeslot__block {
        padding-bottom: 12px;
    }

    .form-checkin-arrivalat__place,
    .form-checkbox-group__item {
        margin-bottom: 12px;
    }

    .form-checkbox-group__item + p {
        margin-top: -6px;
        margin-bottom: 12px;
    }

    .form-checkin-arrivalat__time {
        display: flex;
        align-items: center;
    }


    .form-checkin-timeslot__block .form-dropdown__select {
        font-size: 12px;
    }

    .form-dropdown {
        margin-bottom: 0;
    }

    .form-date {
        flex: 1;
    }
}

h2.success-checkin {
    font-size: 21px;
}

.checkin-summary {
    font-size: 15px;

    @media #{$screen-tablet-desktop} {
         font-size: 18px;
    }

    .block-list__item__content {
        display: flex;
        flex-direction: column;
        padding: 7px 21px 7px 12px;

        @media #{$screen-tablet-desktop} {
            flex-direction: row;
            padding: 12px 21px 12px 12px;
        }

        .member {
            width: 40%;
            font-weight: bold;
            line-height: initial;
        }

        .note-wrapper {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 1.7em;


             @media #{$screen-tablet-desktop} {
                align-self: center;
                line-height: initial;
                font-size: 16px;
            }

            @media #{$screen-desktop} {
                flex-direction: row;
                width: 60%;
            }
        }

        .note1 {
            flex: 1 1 auto;
        }
        .note2 {
            flex: 1 1 auto;
        }
    }

    &.success {
         .note1 {
            @media #{$screen-desktop} {
                width: 30%;
            }
        }
    }
}
