.l-tiles.force-reminder {
    position: relative;

    .tile:not(.is-checkin) {
        filter: blur(5px);
        pointer-events: none;
    }
    // IE11
    @media all and (-ms-high-contrast: none) {
        .tile:not(.is-checkin) {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    .force-popup {
        position: absolute;
        background-color: $color-white;
        top: 260px;
        left: 0;
        right: 12px;
        padding: 16px;
        box-shadow: 0 4px 9px 0 rgba(12, 20, 35, 0.15);
        border-radius: 10px;
        z-index: 50;
        transition: opacity 250ms 1750ms ease-in-out;
        opacity: 1;
        font-family: SourceSansPro;

        @media #{$screen-tablet-desktop} {
            top: 320px;
            width: 390px;
            padding: 24px;
        }

        @media #{$screen-desktop} {
            top: 120px;
            left: 330px;
        }
        
        .arrow-left {
            position: absolute;
            top: -14px;
            left: 50%;
            width: 0;
            height: 0;
            margin-left: -10px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 14px solid $color-white;

            @media #{$screen-tablet-desktop} {
                left: 120px;
                margin-left: 0;
            }

            @media #{$screen-desktop} {
                left: -26px;
                top: 92px;
                border-top: 13px solid transparent;
                border-bottom: 13px solid transparent;
                border-right:16px solid $color-white;
            }
        }

        &.health {
            top: 260px;

            @media #{$screen-tablet-desktop} {
                top: 320px;
                width: 410px;
            }
           
            @media #{$screen-desktop} {
               top: 140px;
            }

            .arrow-left {
                @media #{$screen-desktop} {
                    top: 70px;
                }
            }
        }

        .head {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 0 9px;

            > h2 {
                font-size: 16px;
                font-family: SourceSansPro;
                margin: 0 0 0;

                @media #{$screen-tablet-desktop} {
                    font-size: 21px;
                }
            }

            > svg.clock {
                margin-right: 6px;
                margin-bottom: 1px;
                transform: scale(0.834);

                @media #{$screen-tablet-desktop} {
                    margin-right: 10px;
                    transform: scale(1);
                }
            }
            .icon.healthy {
                padding-right: 9px;
                font-size: 30px;
            }
        }

        .cta {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            padding-top: 16px;

            .button {
                width: 48%;
                padding: 7px 10px;
                font-family: SourceSansPro;
                font-size: 15px;

                @media #{$screen-tablet-desktop} {
                    font-size: 18px;
                    line-height: 1.25;
                }
            }
    
            &.health > .button {
                width: 39%;
                padding: 7px 5px;
                
                &.dark {
                    width: 55%;
                }

                @media #{$screen-tablet-desktop} {
                    width: unset;
                    padding: 7px 18px;

                    &.dark {
                        width: unset;
                    }
                }
            }

        }

         &.reminder {
            opacity: 0;
            @media #{$screen-desktop} {
                top: 166px;
            }

             .arrow-left {
                @media #{$screen-desktop} {
                    top: 46px;
                }

            }

            .head {
                margin: 0 0 0;

                h2 {
                    font-size: 16px;

                    @media #{$screen-tablet-desktop} {
                        font-size: 20px;
                    }
                }

                > svg.reminder {
                    width: 60px;
                    transform: scale(0.834);
                    margin-right: 5px;

                    @media #{$screen-tablet-desktop} {
                        width: 70px;
                        transform: scale(1);
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}