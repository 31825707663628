.page-vip-tariff {
  .headline.less-margin {
    margin-bottom: 10px;
  }

  .description {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 41px;
      margin-bottom: 30px;

      @media #{$max-screen-tablet-desktop} {
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }

    li {
      @extend .icon;
      @extend .checked;

      position: relative;
      list-style-type: none;
      width: 50%;
      box-sizing: border-box;
      padding: 0 25px 10px 15px;

      &:nth-child(even) {
        padding: 0 0 10px 15px;
      }

      &::before {
        position: absolute;
        top: 2px;
        left: -16px;
        font-size: 18px;
      }

      @media #{$max-screen-tablet-desktop} {
        width: 100%;
        padding: 0 0 10px 15px;
      }
    }
  }

  .clear {
    clear: both;
  }

  .info-link {
    margin-bottom: 20px;

    @media #{$max-screen-tablet-desktop} {
      margin-bottom: 10px;
    }

  }

  .spacer {
    margin-bottom: 50px;
  }

  .vip-coupon {

    &.active {

      .infobox {
        background-color: $color-tuic-blue-white;
      }
    }

    .loading-indicator {
      margin: 52px auto 56px auto;
    }

    .infobox.svg {
      margin: 0 0 20px 0;
      padding: 28px 28px 22px 28px;
    
      .icon-wrapper {
        vertical-align: unset;
        width: 50px;

        .inline-svg {
          margin-top: -3px;
        }
      }
    }
  
    h2 {
      margin: 0 0 5px;
    }

    a {
      text-decoration: underline;
    }
  }
}
