.accordion {
    width: 100%;

    &-item {
        border-top: 1px solid $color-tuic-border-dark;
        padding: 15px 12px 0 12px;

        &:last-child {
            border-bottom: 1px solid $color-tuic-border-dark;
        }

        &__header {
            position: relative;
            @extend .font-tuic-icon;
            @extend .icon-arrow-down;
            cursor: pointer;

            &::before {
                font-size: 14px;
                position: absolute;
                top: 6px;
                right: 4px;
            }
        }

        &__content {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.5s;
        }

        &--expanded  &__header {
            @extend .font-tuic-icon;
            @extend .icon-arrow-up;
        }

        &--expanded &__content {
            max-height: none;
        }
    }
}

@media print {
    .accordion {
        width: 100%;

        &-item {
            border-color: $color-black;

            &:first-child,
            &:last-child {
                border: 0;
            }

            &__content {
                max-height: none;
                overflow: visible;
            }
        }
    }
}
