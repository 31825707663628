
.form-dropdown {
    margin-bottom: 12px;

    &.error {
        margin-bottom: 0;
    }
}

.form-dropdown__label {
    @include font(13px, 1.5);
    display: block;
    position: relative;
    color: $color-tuic-font-default;
    background-color: $color-tuic-blue-white;
    min-width: 67px;
    box-sizing: border-box;

    &::before {
        speak: none;
        font-size: 1em;
        vertical-align: baseline;
        text-transform: none;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        font-family: fontcustom;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
        content: '\f10f';
        display: inline-block;
        position: absolute;
        padding-left: 7px;
        bottom: 0;
        right: 0;
        width: 25px;
        height: 36px;
        line-height: 43px;
        text-align: left;
        background-color: $color-tuic-blue-white;
        pointer-events: none;
    }

    &.disabled {
        &::before {
            color: $color-tuic-blue-white;
            background: $color-tuic-blue-light;
        }
    }

    &.error {
        &::before {
            border-top: 1px solid $color-tuic-red-light;
            border-right: 1px solid $color-tuic-red-light;
            border-bottom: 1px solid $color-tuic-red-light;
            height: 34px;
        }
    }
}

.form-dropdown__label__text {
    padding-bottom: 2px;
    background-color: $color-tuic-bg;

    &.has-frame {
        background-color: $color-tuic-blue-light;
    }

    &.error {
        color: $color-tuic-red;
    }
}

.form-dropdown__select {
    @include font(13px, 1.5);
    display: inline-block;
    margin: 0;
    padding: 8px 10px 8px 9px;
    width: 100%;
    height: 36px;
    line-height: 1.5;
    background-color: $color-transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    overflow: visible;
    color: $color-tuic-font-default;

    &:focus:-moz-focusring {
        color: $color-transparent;
        text-shadow: 0 0 0 $color-black;
    }

    &::-ms-expand {
        visibility: hidden;
    }

    &.error {
        border: 1px solid $color-tuic-red-light;
    }

    &.disabled {
        background: $color-tuic-blue-light;
        color: $color-tuic-blue-white;
    }
}


@media #{$screen-desktop} {
    .form-dropdown__label {
        max-width: 272px;

        &.fixedwidth {
            width: 272px;
        }
    }
}

.form-select-info {
    padding: 0 0 18px 12px;
}
