$x-padding: 12px;
$y-padding: 12px;

.booking-table {
    width: 100%;
    margin-bottom: 12px;

    .booking-table__header {
        display: none;
    }

    > .booking-table__header,
    > .booking-table__footer {
        font-weight: bold;
    }

    .booking-table__cell {
        padding: $y-padding $x-padding;

        &.empty {
            display: none;
        }

        &.align-bottom {
          vertical-align: bottom;
        }

        & .has-info {
            .name {
                font-weight: bold;
            }

            .additional-info {
                margin-top: 10px;
            }

            .operator-line-1,
            .operator-line-2 {
                .field {
                    white-space: nowrap;
                }
            }

            .operator-line-2 {
                @include font(12px, 1.4);
                margin-top: 4px;
                color: $color-tuic-blue-info;
            }
        }
    }

    .booking-table__empty-text {
        @include font(15px, 20px);
        color: $color-tuic-blue-dark;
        padding: 16px 14px;
    }

    .no-padding {
        .booking-table__cell {
            padding-top: 0;
            padding-bottom: 0;
        }

        .booking-table__row {
            border: 0;

            &:first-child {
                padding-top: $y-padding;
            }

            &:last-child {
                padding-bottom: $y-padding;
                border-bottom: 1px solid $color-tuic-border-dark;

                &.no-border {
                  border: 0;
                }
            }
        }
    }

    .booking-table__row {
        border-bottom: 1px solid $color-tuic-border-dark;
    }

    .booking-table__footer .booking-table__row {
        border: 0;
    }

    .booking-table__caption {
      margin-top: 16px;
      margin-bottom: 40px;
      text-align: left;
    }

    .booking-table__link {
      border: 0;

      a {
        text-decoration: underline;
      }

      .booking-table__cell {
        padding-bottom: 0;
      }
    }
}

.booking-confirmation__warning {
    margin-right: 0;
    button {
        width: 100%;
    }
}

.is-cancellation {
    .booking-table__price,
    .booking-table__name,
    .booking-table__info {
        text-decoration: line-through;
    }
}

.booking-table__cancel-footer,
.booking-table__price {
    text-align: right;
    white-space: nowrap;
}

.booking-table__info {
    color: $color-tuic-blue-info;
}

.booking-table__error {
    padding-top: 8px;
    color: $color-tuic-red;
}

@media #{$screen-tablet-desktop} {
    .booking-table:not(.booking-table__confirmation) {
        display: table;

        > .booking-table__header {
            display: table-header-group;
        }

        > .booking-table__body {
            display: table-row-group;
        }

        > .booking-table__footer {
            display: table-footer-group;
        }

        .booking-table__row {
            display: table-row;
        }

        .booking-table__cell {
            display: table-cell;

            &.empty {
                display: table-cell;
            }
        }
    }

    .booking-table:not(.booking-table__confirmation) {
        > .booking-table__header,
        > .booking-table__footer {
            @include font(15px, 1.5);
        }

        .booking-table__row {
            display: table-row;
        }

        .no-padding {
            .booking-table__cell {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        > .booking-table__header,
        > .booking-table__body {
            .booking-table__row:first-child > .booking-table__cell {
                padding-top: $y-padding;
                line-height: 1.4;
            }

            .booking-table__row:last-child > .booking-table__cell {
                padding-bottom: $y-padding;
                border-bottom: 1px solid $color-tuic-border-dark;

                &.no-border {
                  border: 0;
                }
            }
        }
    }

    .booking-confirmation__warning {
        margin-right: -12px;
        button {
            width: auto;
        }
    }
}

.booking-table.booking-table__confirmation {
  display: table;

  > .booking-table__header {
    display: table-header-group;
  }

  > .booking-table__body {
    display: table-row-group;
  }

  > .booking-table__footer {
    display: table-footer-group;
  }

  .booking-table__row {
    display: table-row;
  }

  .booking-table__cell {
    display: table-cell;

    &.empty {
      display: table-cell;
    }
  }

  .last-border:last-child,
  .border-bottom {
    border-bottom: 1px solid $color-tuic-border-dark;
  }

  > .booking-table__header,
  > .booking-table__body {
    .booking-table__row:first-child > .booking-table__cell {
      padding-top: $y-padding;
      line-height: 1.4;
    }

    .booking-table__row:last-child > .booking-table__cell {
      padding-bottom: $y-padding;
    }
  }
}

@media print {
    .booking-table {
        .booking-table__row {
            border-color: $color-black;
        }

        > .booking-table__header,
        > .booking-table__body {
            .booking-table__row:last-child > .booking-table__cell {
                border-color: $color-black;
            }
        }
    }

    .booking-table__info {
        color: $color-black-60;
    }
}

.booking-table.excursion,
.booking-table.inclusive-booking,
.booking-table.additional-services {
  .booking-table__row.no-border {
    border: 0;
  }

  .discount {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .big {
    font-size: 18px;
  }

  @media #{$screen-no-desktop} {
    .booking-table__price.discount {
      margin-top: -27px;

      .big {
        margin-bottom: 12px;
      }
    }
  }

  @media screen and (max-width: 643px) {
    .booking-table__price.discount {
      .big {
        margin-top: -8px;
      }
    }

  }

  @media screen and (max-width: 340px) {
    .big {
      font-size: 16px;
    }

    .booking-table__price.discount {
      .big {
        margin-top: -5px;
      }
    }
  }
}

.booking-table.excursion {
  .booking-table__spacer-row {
    height: 6px;
  }

  .booking-table__footer {
    .booking-table__name {
      padding-top: 4px !important;
    }

    .booking-table__price {
      font-size: 18px;
    }
  }
}

