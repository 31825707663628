.inclusive-excursion {
  .headline {
    margin-bottom: 30px;
    @media #{$screen-tablet-desktop} {
      margin-bottom: 20px;
    }
  }

  p.description {
    color: inherit !important;
    font-size: inherit !important;

    span {
      color: inherit !important;
      font-size: inherit !important;
    }
  }

  button.full-width {
    width: 100%;
  }

  .inclusive {
    padding-left: 4px;
  }

  .slider-item {
    img {
      max-height: 465px;
    }
  }

  .has-error {
    color: $color-tuic-red;
  }
}
