.form-textarea {
    margin-bottom: 12px;
}

.form-textarea__label {
    @include font(13px, 1.5);
    display: block;
    margin: 0 0 4px;
}

.form-textarea__textarea {
    @include font(13px, 1.5);
    display: block;
    overflow: auto;
    box-sizing: border-box;
    padding: 2px 8px;
    background: $color-tuic-blue-white;
    border: 0;
    color: $color-tuic-font-default;
    width: 100%;
    height: 72px;
    resize: none;

    &.disabled {
        background: $color-tuic-blue-light;
        color: $color-tuic-blue-white;
        -webkit-text-fill-color: $color-tuic-blue-white;
        opacity: 1;
    }

    &:focus {
        border-style: none;
        border-color: $color-transparent;
        overflow: auto;
        outline: none;
    }

    &.error {
        border: 1px solid $color-tuic-red-light;
    }
}

.form-textarea__remaining {
    @include font(11px, 1.5);
    float: right;
    width: 2em;
    padding-top: 2px;
    text-align: right;

    &.warning {
        color: $color-tuic-red;
    }
}
