.itinerary-item {
    display: block;
    position: relative;
    margin: 0 0 4px;
    padding: 12px;
    padding-left: 34px;
    color: inherit;
    background: none;
    text-decoration: none;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }

    &:only-child {
        padding-left: 20px;
    }

    &.services {
        min-height: 84px;
        background: $color-tuic-blue-white;

        &:hover {
            background-color: $color-tuic-blue-light-hover;
            color: $color-white;

            &::after {
                border: 2px solid $color-tuic-blue-light-hover;
            }
        }

        &.disabled {
            cursor: default;

            &:hover {
                background: $color-tuic-blue-light;
                color: $color-tuic-blue-white;
            }

            &::after {
                border: 2px solid $color-tuic-blue-light;
            }
        }

        &.readOnly {
            background: $color-tuic-blue-light;

            &:hover {
                background: $color-tuic-blue-light;
                color: inherit;
            }

            &::after {
                border: 2px solid $color-tuic-blue-light;
            }
        }

        &::after {
            border: 2px solid $color-tuic-blue-white;
        }
    }

    &.disabled {
        background: $color-tuic-blue-light;
        color: $color-tuic-blue-white;
    }

    &::before {
        display: block;
        content: '';
        background: $color-tuic-blue-dark;
        position: absolute;
        width: 2px;
        left: 17px;
        top: -4px;
        bottom: -2px;
    }

    &:first-child::before {
        top: 34px;
    }

    &:last-child::before {
        bottom: auto;
        height: 38px;
    }

    &:only-child::before {
        content: none;
    }

    &::after {
        display: block;
        content: '';
        background: $color-tuic-blue-dark;
        border-radius: 50%;
        position: absolute;
        top: 32px;
        left: 10px;
        width: 12px;
        height: 12px;
        border: 2px solid $color-tuic-bg;
    }

    &:only-child::after {
        content: none;
    }

    &.highlight {
        border: 2px solid $color-tuic-blue-dark;
        padding: 10px 32px 10px 32px;
        &::after {
            left: 8px;
            top: 30px;
        }

        &::before {
            bottom: -4px;
            left: 15px;
        }
    }

    &.teaser {
      min-height: 90px;
      padding: 24px 34px;
      color: $color-white;
      background: linear-gradient(110deg, #263e6a 0%, rgba(21, 45, 89, 0.95) 53%, rgba(0, 24, 68, 0));
      p {
        margin: 6px 0 12px 0;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 138px;
        z-index: -1;
      }
    }

    &.teaser::before {
      bottom: auto;
      height: 4px;
      top: -4px;
    }

    &.teaser::after {
      display: none;
    }
}

.itinerary-item__date {
    margin-top: -4px;

    .offer-item &,
    .small & {
        font-weight: bold;
    }
}

.itinerary-item__title {
    @include font(15px);

    .small & {
        margin-top: 6px;
        font-size: 13px;
    }
}

.itinerary-item__image {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 142px;
    height: 80px;
    background: $color-tuic-blue-dark;
    display: none;

    &.disabled {
        opacity: .5;
    }

    img {
        max-width: 100%;
    }
}

.itinerary-item__event-list {
    list-style: none;
    padding: 2px 0 0;
    margin: 0;
}

.itinerary-service__time {
    position: relative;
    font-weight: bold;
    margin-right: 4px;

    &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -17px;
        width: 2px;
        background: $color-tuic-blue-dark;
    }

    &.time {
        &::before {
            display: block;
            content: '';
        }

        &::after {
            display: block;
            content: '';
        }
    }

    &:first-of-type::before {
        top: -9px;
    }

    &:last-of-type::before {
        top: 0;
        height: 6px;
    }

    &::after {
        position: absolute;
        left: -20px;
        top: 4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $color-tuic-blue-dark;
    }

    .itinerary-item:only-child &::before,
    .itinerary-item:only-child &::after {
        display: none;
    }

    .itinerary-item:last-child &::before {
        top: -34px;
        height: 40px;
    }
}

.wait-listed-participant,
.wait-listed {
  font-size: 11px;
  background-color: $color-tuic-blue-light-bg;
  margin: 7px 0 4px 0;
  padding: 11px 9px 10px 12px;
  text-align: left;
}

.wait-listed-participant {
  padding: 9px 9px 8px 12px;
  /* margin: 0 0 10px 0; */
  margin: -2px 0 14px 0
}

.mark-wait-list::after,
.mark-wait .form-checkbox-group__item__label::after,
.wait-listed-point::after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 7px;
  border-radius: 20px;
  background-color: $color-tuic-blue-light-bg;
}
.wait-listed-detail {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 13px;
  background-color: $color-tuic-blue-light-bg;
  margin: -20px 0 20px 0;
  padding: 14px 10px 12px 10px;

  @media #{$screen-tablet-desktop} {
     font-size: 14px;
     padding: 25px 24px 23px 20px;
  }

  .icon-wrapper {
    display: none;

    @media #{$screen-tablet-desktop} {
      display: block;
      margin: 0 10px 0 0;
    }
  } 
} 

@media #{$screen-tablet-desktop} {
    .itinerary-item {
        padding-right: 166px;

        &.highlight {
            padding-right: 165px;
        }
    }

    .itinerary-item__title {
        @include font(21px);
    }

    .itinerary-item__image {
        display: block;
    }
}

@media print {
    .itinerary-item {
        padding: 30px 12px;
        border-bottom: 1px solid $color-black;

        &::before,
        &::after {
            display: none;
        }

        &.highlight {
            padding: 29px 11px;
        }
    }

    .itinerary-service__time {
        &.time {
            &::before,
            &::after {
                display: none;
            }
        }
    }
}
