.form-radio {
    display: block;
    cursor: pointer;
    margin-bottom: 16px;

    &.error {
        color: $color-tuic-red;
    }
}

.form-radio__input {
    position: absolute;
    visibility: hidden;

    ~ .check {
        content: '';
        display: inline-block;
        width: $radio-size;
        height: $radio-size;
        margin-right: 0.4em;
        border-radius: 50%;
        border: 1px solid $color-tuic-blue-dark;
    }

    &:checked ~ .check {
        background-color: $color-tuic-blue-dark;
        position: relative;

        &::before {
            content: '';
            display: inline-block;
            width: $radio-inner-size;
            height: $radio-inner-size;
            position: absolute;
            top: ($radio-size - $radio-inner-size) / 2;
            left: ($radio-size - $radio-inner-size) / 2;
            background: $color-tuic-bg;
            border-radius: 50%;
        }
    }
}

.form-radio.light-blue {
    .form-radio__input {
        ~ .check,
        &:checked ~ .check {
            background-color: transparent;
            position: relative;
            margin: 12px 10px 10px 10px;
            height: 14px;
            width: 14px;
            border-radius: 50%;
            border: 2px solid $color-tuic-blue-light-bg;
        }

        &:checked ~ .check {
            &::before {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 8px;
                height: 8px;
                margin: -4px 0 0 -4px;
                background: $color-tuic-blue-dark;
            }
        }
    }

}
