.appStoreTile {
    float: left;
    margin-top: 15px;
    width: 130px;
    height: 40px;

    &:first-of-type {
        margin-right: 5px;
    }
}
