.page-booking-details {
  .headline {
    margin-bottom: 0;
  }

  .l-tiles {
    .headline {
      margin: 0 0 20px 12px;
    }

    &.m-top {
      margin-top: 30px;
    }
  }

  // TODO check if this fix should be applied to button in base.css
  // see TUICUNIT-1626
  .button {
    margin: 0 6px 6px 0;
  }

  p.info {
    @include font(21px, 27px);
    color: $color-tuic-blue-dark;
    margin-bottom: 40px;
  }

  .infobox .head h2 {
    margin-bottom: 6px;
  }

  .infobox h3 {
    font-size: 16px;
  }

  .infobox h4 {
    // emit baseline vertical-align
    line-height: 28px;
    margin-bottom: 8px;
  }

  @media #{$max-screen-tablet-desktop} {
    .infobox img {
      width: 70%;
    }
  }

  @media #{$screen-tablet-desktop} {
    .infobox .l-col {
      clear: none;
      width: $column-width;
      margin: 0
    }
  }

  @media #{$screen-desktop} {
    .infobox .l-col {
      margin: 0 12px 0 0;
    }
  }

  .page-insurance {
    .infobox {
      background-color: initial;
    }
  }
}
