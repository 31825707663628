@import
'arrival-departure',
'booking-details',
'checkin',
'login',
'not-found',
'parking',
'vip-tariff',
'inclusive-excursion',
'manifest',
'health-info',
'travel-documents',
'survey',
'survey-detail',
'survey-health',
'survey-flight'
;


.page.error {
  min-height: 20vh;

  .error-message--form {
    font-family: SourceSansPro;
    font-size: 15px;
  }
}