.info-list {
    display: table;
    width: 100%;
    margin-bottom: 20px;

    &__item {
        display: table-row;

        > * {
            padding: 2px 4px;
        }

        &:first-of-type > * {
            padding-top: 0;
        }

        &:last-of-type > * {
            padding-bottom: 0;
        }

        &__key {
            display: table-cell;
            width: 25%;
            font-weight: bold;

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }

        }

        &__value {
            display: table-cell;
            width: 75%;

            > a {
              text-decoration: underline;
            }
        }
    }

    &.spaced .info-list__item > * {
        padding: 6px 4px;
    }
}
