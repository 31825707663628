.bookings-list {
    display: table;
    width: 100%;
    margin-bottom: 12px;
}

.bookings-list__item {
    display: table;
    width: 100%;
    padding: 20px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: $color-tuic-border-dark $color-transparent $color-tuic-blue-light;
}

.bookings-list__item:first-child,
.bookings-list__header + .bookings-list__item {
    border-top: 0;
    padding-top: 0;
}

.bookings-list__item:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.bookings-list__item__title {
    @include font(15px);
    margin-bottom: 6px;
}

.bookings-list__item__info {
    @include font(12px);
}

@media #{$screen-tablet-desktop} {
    .bookings-list__item__content {
        display: table-cell;
        margin-bottom: 0;
        padding-right: 6px;
    }

    .bookings-list__item__title {
        @include font(21px);
    }

    .bookings-list__item__info {
        @include font(15px);
    }

    .bookings-list__item__button {
        padding-left: 6px;
        display: table-cell;
        vertical-align: middle;
        text-align: right;
        width: 176px;
    }
}
