.manifest {
    .overview-flex {
        display: flex;
        flex-direction: column;
        margin-top: -10px;

        &.p2 {
            @media #{$screen-tablet-desktop} {
                flex-direction: row;
                padding-bottom: 15px;
            }
        }

        .part {
            display: flex;
            flex-direction: column;
            float: none;
            justify-content: space-between;
            width: 100%;

            span {
                padding-top: 30px;

                @media #{$screen-tablet-desktop} {
                    padding-top: 20px;
                }
            }

            h2 {
                display: flex;
                align-items: center;
                margin: 0 0 8px;
                font-size: 15px;

                @media #{$screen-tablet-desktop} {
                    margin: 0 0 4px;
                    font-size: 18px;
                    min-height: 2.4em;
                }
            }

            .link {
                text-align: right;
                margin: 0 0 0 -12px;
                padding-top: 4px;

                a {
                    position: relative;
                    width: 100%;
                    text-align: center;
                    line-height: 1.9;
                }
            }
        }

        @media #{$screen-tablet-desktop} {
            .part .link {
                margin: 0 -12px;
                padding-top: 12px;

                a {
                    width: 100%;
                    line-height: 1.5;
                }
            }
        }

        @media #{$screen-desktop} {
            &.p2 {
                .part {
                    width: $column-width;
                    min-height: 205px;
                }
            }
        }
    }

    .form-covid {

        .form-checkbox.big {
            height: auto;
            margin-bottom: 4px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 7px;
            padding: 0 0 0 42px;
            list-style: initial;
            list-style-position: outside;
            
            li {
                position: relative;
                width: 100%;
                margin-left: 20px;
                margin-bottom: 2px;
                font-size: 5px;


                @media #{$screen-tablet-desktop} {
                    width: 45%;
                    margin-bottom: 4px;
                }

                span {
                    position: relative;
                    top: 3px;
                    font-size: 12px;
                }
            } 
        }

        .accordion {
            width: initial;
            padding-left: 50px;
        }

        .accordion-item {
            padding: 0;
            border: 0;

            h2 {
                display: inline-block;
                margin: 0;
                font-size: 13px;

                &:before {
                    top: 3px;
                    right: -20px;
                    font-size: 10px;
                } 
            }

            .accordion-item__content {
                padding-top: 10px;
            }
        }
    }
}
