.booking-confirmation__buttons {
    text-align: right;
}

.booking-confirmation__error {
    color: $color-tuic-red;
}

.inclusive-excursion {
  .booking-confirmation {
    .headline__h2 {
      font-size: 19px;
    }

    .booking-table__name {
      font-weight: bold;

      .waived {
        padding-bottom: 4px;
      }
    }

    .booking-table__info {
      color: $color-tuic-blue-dark;
    }

    .waived-table {
      .booking-table__name,
      .booking-table__hint {
        display: block;
      }

      @media #{$screen-tablet-desktop} {
        .booking-table__name,
        .booking-table__hint {
          display: table-cell;
        }

        .booking-table__hint {
          width: 50%;
          max-width: 456px;
        }
      }
    }
  }
}
