body {
    @include font(13px, 1.5);
    padding: 0 0 40px;
    margin: 0;
    color: $color-tuic-font-default;
    background: $color-tuic-bg;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

a {
    color: inherit;
    text-decoration: none;
    background-color: $color-transparent;

    &:active,
    &:hover {
        outline: 0;
    }

    &.underline {
        text-decoration: underline;
    }

    &.inline {
        color: $color-tuic-blue-mid;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }
}

i {
    font-style: italic;
}

b,
strong,
.bold {
    font-weight: bold;
}

h1 {
    @include font(21px, 1.2);
    margin: 0 0 20px;
}

h2 {
    @include font(15px, 1.2);
    margin: 0 0 12px;
}

h3 {
    @include font(13px, 1.5);
    margin: 0 0 12px;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    width: 100%;
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    box-sizing: content-box;
    height: 0;

    margin: 7px -12px 20px;
    border-width: 1px 0;
    border-style: solid;
    border-color: $color-tuic-border-dark $color-transparent
        $color-tuic-border-light;
}

pre {
    overflow: auto;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input:invalid {
    box-shadow: none;
    outline: none;
}

button {
    overflow: visible;
}

.button,
button {
    @include font(12px, 1.5);
    display: inline-block;
    margin: 0 0 0 6px;
    padding: 7px 20px;
    border: 0;
    background: $color-tuic-blue-white;
    color: $color-tuic-font-default;
    text-decoration: none;
    box-sizing: border-box;
    cursor: pointer;

    &:first-of-type,
    .block + & {
        margin-left: 0;
    }

    .block + & {
        margin-top: 12px;
    }

    &.full-width {
        width: 100%;
    }

    &:hover {
        background: $color-tuic-blue-light-hover;
        color: $color-white;
    }

    &:focus {
       outline: none;
    }


    &.dark {
        text-align: center;
        color: $color-white;
        background: $color-tuic-blue-dark;
        width: 100%;
        font-size: 15px;

        & + & {
            margin: 6px 0 0;
        }

        &:hover {
            background: $color-tuic-blue-light-hover;
        }
    }

    &.white {
        background: $color-white;

        &:hover {
            color: $color-tuic-blue-light-hover;
        }
    }

    &.link {
        background: inherit;
        color: inherit;
        padding-left: 0;

        &:hover,
        &:focus {
            outline: none;
            text-decoration: underline;
        }
    }

    &.disabled,
    &[disabled] {
        @extend %disabled;
        background: $color-tuic-blue-light;
        color: $color-tuic-blue-white;
        cursor: default;

        &:hover {
            background: $color-tuic-blue-light;
            color: $color-tuic-blue-white;
        }
    }
}

button,
select {
    text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    height: auto;
}

input[type='search'] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

fieldset {
    //border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: bold;
}

td,
th {
    padding: 0;
}

em {
    font-style: italic;
}

p {
    margin: 0 0 12px;

    &.annotation {
        @include font(11px, 1.5);
    }

    &.info {
        color: $color-dark-blue-50;
    }

    &.ample {
        line-height: 1.7;
    }
}

ul {
    list-style: none;
    margin: 0 0 12px;
    padding: 0;
}

.main-container {
    width: 100%;
    height: 100%;
    background: $color-transparent;
    background-repeat: no-repeat;
    background-position: 50% 20px;
    background-size: 80% auto;
}

.main-app {
    display: block;
    padding: 92px 12px 0;

    &.without-header {
        padding: 20px 12px 0 12px;
    }

    @media screen and (max-width: 643px) {
        overflow-x: hidden;
    }
}

.spacer {
    visibility: hidden;
}

.is-hidden {
    display: none;
}

.icon-link,
.icon-link-up {
    position: relative;
    top: 1px;
    width: 13px;
    height: 13px;
    margin-right: 5px;
}

.icon-link-up {
    transform: rotate(180deg);
}

.table {
    display: table;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
}

.no-wrap {
    white-space: nowrap;
}

.align-left {
  text-align: left;
}

@media #{$screen-tablet-desktop} {
    ul {
        &.action {
            @include font(15px, 1.5);
        }
    }

    .button,
    button {
        @include font(15px, 1.5);

        &.dark {
            display: inline-block;
            width: auto;

            & + & {
                margin: 0 0 0 12px;
            }
        }

        &.block {
            display: block;
            width: 100%;
        }

        &.big {
            @include font(21px, 1.2);
            padding: 16px 18px;
        }

        &.link {
            @include font(12px);
        }
    }

    h1 {
        @include font(30px, 1.2);
    }

    h2 {
        @include font(21px, 1.2);
    }

    h3 {
        @include font(15px, 1.5);
    }
}

@media print {
    body {
        font-family: arial, sans-serif;
        font-size: 11px;
        line-height: 1.5;
        padding: 0;
        background: $color-white;
        color: $color-black;
    }

    hr {
        border-color: $color-black;
        border-width: 1px 0 0;
    }

    .no-print,
    .button,
    button {
        display: none;

        &.dark {
            display: none;
        }
    }
}

@media #{$screen-tablet-desktop}, print {
    .main-app {
        padding-top: 0;
    }
}
