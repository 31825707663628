$item-margin: 3px;
$item-padding-x: 21px;
$item-padding-y: 7px;

.block-list {
    @include font(12px, 1.5);
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
    line-height: 1.9;

    &.inverted {
        margin-left: -$gutter-padding;
        margin-right: -$gutter-padding;
    }

    &__title {
        font-weight: bold;
        padding: 7px 21px;

        .block-list.inverted & {
            padding: 7px 12px;
        }
    }

    &__item {
        min-height: 22px;
        color: inherit;
        background: $color-tuic-blue-white;
        margin-bottom: $item-margin;

        &:hover {
            background-color: $color-tuic-blue-light-hover;
            color: $color-white;
        }

        .block-list.inverted & {
            background: transparent;
            border-bottom: 1px solid $color-tuic-border-dark;
            margin-bottom: 0;

            &:first-child {
                border-top: 1px solid $color-tuic-border-dark;
            }

            &:hover {
                background-color: inherit;
                color: inherit;
            }
        }

        a {
            cursor: pointer;
        }

        &.read-only {
            background: $color-tuic-blue-light;

            &:hover {
                cursor: default;
                color: inherit;
                background: $color-tuic-blue-light;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &__content {
            display: block;
            padding: 7px 21px;
            color: inherit;
            text-decoration: none;

            &:hover {
                color: inherit;
            }

            &.has-click {
                cursor: pointer;
            }
        }
    }
}

@media #{$screen-tablet-desktop} {
    .block-list {
        @include font(15px, 1.5);
    }
}

@media print {
    .block-list__item {
        background-color: $color-white;
        padding: 1px 12px;
    }
}
