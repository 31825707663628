.arrival-departure-item {
    display: block;
    position: relative;
    margin: 0 0 4px;
    padding: 12px 12px 20px 24px;
    color: inherit;
    background: none;
    text-decoration: none;

    .mobile,
    .tablet {
        display: none;
    }

    .mobile {
        @media screen and (max-width: 583px) {
            display: block;
        }
    }

    .tablet {
        @media screen and (min-width: 584px) {
            display: block;
        }
    }

    .accordion-item,
    .accordion-item:last-child {
        border: 0;
    }

    .accordion-item {
        padding: 0;

        .accordion-item__header {
            background-position: 99% 30px;
            padding-left: 10px;
            -webkit-tap-highlight-color: transparent;

            &::before {
                top: 26px;
                right: 14px;
            }
        }

        .accordion-item__content {
            background-color: $color-tuic-blue-light;
            color: $color-tuic-blue-dark;

            &:hover {
                background-color: $color-tuic-blue-light;
                color: $color-tuic-blue-dark;
            }
        }
    }

    .common-hint {
        padding: 20px 10px 0 10px;
        opacity: 0.5;

        a {
            display: block;
            margin-top: 10px;
            text-decoration: underline;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.bus,
    &.train,
    &.train-change,
    &.flight {
        min-height: 84px;
        background: $color-tuic-blue-white;
    }

    &.bus {
        .bus-header {
            padding-left: 10px;
        }
    }

    &.train,
    &.train-change {
         padding: 12px 12px 10px 24px;
     }

    &.services {
        &:hover {
            background-color: $color-tuic-blue-light-hover;
            color: $color-white;

            &::after {
                border: 2px solid $color-tuic-blue-light-hover;
            }
            
            &.train {
                .arrival-departure-service__time.arrival {
                    background-color: $color-tuic-blue-light-hover;
                    color: $color-white;
                }
                .seat-info {
                    background-color: $color-tuic-blue-light-hover;
                }
                .ticket {
                    color: $color-tuic-blue-dark;
                }
            }
            &.train-change {
                .accordion-item__content {
                    background-color: $color-tuic-blue-light-hover;
                    color: $color-white;
                }
            }
        }

        &.disabled {
            cursor: default;

            &:hover {
                background: $color-tuic-blue-light;
                color: $color-tuic-blue-white;
            }

            &::after {
                border: 2px solid $color-tuic-blue-light;
            }
        }

        &.readOnly {
            background: $color-tuic-blue-light;

            &:hover {
                background: $color-tuic-blue-light;
                color: inherit;
            }

            &::after {
                border: 2px solid $color-tuic-blue-light;
            }
        }

        &::after {
            border: 2px solid $color-tuic-blue-white;
        }
    }

    &.disabled {
        background: $color-tuic-blue-light;
        color: $color-tuic-blue-white;
    }

    // Bubbleline LINE
    &::before {
        display: block;
        content: '';
        background: $color-tuic-blue-dark;
        position: absolute;
        width: 2px;
        left: 17px;
        top: -2px;
        bottom: -2px;
    }

    &:first-child::before {
        top: -18px;
    }

    &:last-child::before {
        bottom: -18px;
    }

    // Bubbleline BUBBLE
    &::after {
        display: block;
        content: '';
        background: $color-tuic-blue-dark;
        border-radius: 50%;
        position: absolute;
        top: 39px;
        left: 10px;
        width: 12px;
        height: 12px;
        border: 2px solid $color-tuic-bg;
    }

    &.bus {
        // Bubbleline BUBBLE
        &::after {
            border: 2px solid $color-tuic-blue-white;
        }
    }

    &.highlight {
        border: 2px solid $color-tuic-blue-dark;
        padding: 10px 32px 10px 32px;
        &::after {
            left: 8px;
            top: 30px;
        }

        &::before {
            bottom: -4px;
            left: 15px;
        }
    }

    .ticket-wrapper {
        display: flex;
        justify-content: flex-end;

        .loading-indicator {
            margin: 7px 65px 8px 0;
        }
    }

    .ticket {
        display: inline-flex;
        align-items: center;

        .icon {
            position: relative;
            top: 1px;
            font-size: 20px;
            margin-right: 5px;
        }

        &:hover {
            cursor: pointer;
            span {
                color: $color-white !important;
                text-decoration: underline;
            }
            .pdf::before {
                color: $color-white;
                text-decoration: none;
            }
        }
    }

    &.train {
        .accordion-item--expanded {
            padding-bottom: 10px;
        }
        .accordion-item__header {
            margin-bottom: 0;
        }
        .arrival-departure-service__time.arrival {
            margin: 0;
            padding: 3px 0 12px 10px;
            background: $color-tuic-blue-white;
        }
        .seat-info {
            .head {
                font-weight: bold;
            }

            margin: 0;
            padding: 20px 0 0 10px;
            color: $color-seat-info;
            background: $color-tuic-blue-white;
        }

    }
    &.train-change {
        &:after {
            top: 60px;
        }
        .accordion-item__header {
            margin-bottom: 0;
        }

         .accordion-item__header::before {
            top: 21px;
        }

        .arrival-departure-service__time.time {
            position: relative;
        }

        .icon.train-change {
            top: -14px;
        }
        .accordion-item__content {
            padding-left: 10px;
            background-color: transparent;
            color: inherit;
        }
    }
}


.arrival-departure-item__date {
    @include font(13px);
    line-height: 18px;
    &.empty {
        color: transparent;
    }
}

.arrival-departure-item__title {
    @include font(21px);

    line-height: 24px;
    padding: 4px 80px 0 0;
    position: relative;

    .icon.train,
    .icon.take-off {
        font-size: 40px;
        position: absolute;
        top: -4px;
        right: 44px;
    }
}

.arrival-departure-service__time {
    position: relative;

    &.bold {
        font-weight: bold;
    }

    margin: 6px 4px 0 0;
    @include font(13px);
    line-height: 18px;

    .train-change {
        font-size: 40px;
        position: absolute;
        top: -4px;
        right: 44px;
    }
}

.rail-fly-voucher {
  &.active {

    .infobox {
      background-color: $color-tuic-blue-white;
    }
  }

  .l-block {
     margin-right: -12px;
      @media #{$screen-tablet-desktop} {
         margin-right: -24px;
      }
  }
  .infobox.svg {
    margin: 0 0 20px 0;
    padding: 28px 28px 22px 28px;
  
    .icon-wrapper {
      vertical-align: unset;
      width: 50px;

      .inline-svg {
        margin-top: -3px;
      }
    }
  }

  h2 {
    margin: 0 0 5px;
  }

  a {
    text-decoration: underline;
  }
}

@media print {
    .arrival-departure-item {
        padding: 30px 12px;
        border-bottom: 1px solid $color-black;

        &::before,
        &::after {
            display: none;
        }
    }

    .arrival-departure-service__time {
        &.time {
            &::before,
            &::after {
                display: none;
            }
        }
    }
}
