.labeled-input-block__icon {
    display: table-cell;
    vertical-align: middle;
    font-size: 50px;
    line-height: 1;
}

.labeled-input-block__label {
    @include font(21px);
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
    margin-bottom: 12px;
}

@media #{$screen-desktop} {
    .labeled-input-block__head {
        display: block;
        text-align: center;
    }

    .labeled-input-block__icon {
        display: block;
        padding: 14px 0 20px;
        font-size: 120px;
    }

    .labeled-input-block__label {
        @include font(30px);
        display: block;
        padding: 0;
    }
}
