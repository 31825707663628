// scss-lint:disable ColorVariable, HexNotation
.browserwarning {
    position: static;
    top: 0;
    left: 0;
    max-width: 100%;
    background-color: #F5A521;
    padding: 80px 30px 30px;
    margin-bottom: -80px;

    &__headline {
        padding: 0 12px 12px 0;
        font-weight: bold;
        margin: 0;
        opacity: 0.9;
    }

    &__text {
        opacity: 0.9;
        font-size: 0.9rem;
    }

    &__icon {
        position: absolute;
        top: 0;
        right: 10px;
        cursor: pointer;
        margin-top: 30px;
        margin-right: 20px;
        padding-top: 50px;
    }

    &--closed {
        display: none;
    }
}

@media #{$screen-tablet-desktop} {
    .browserwarning {
        padding: 30px;
        margin-bottom: 0;

        &__icon {
            padding-top: 0;
        }
    }

}
