.modal {
    width: 100%;
    outline: none;
    max-width: 500px;
    margin: 16vh auto 0 auto;
}

.modal-content {
    position: relative;
    padding: 20px;
    margin: 12px;
    background: $color-tuic-bg;

    @media screen and (max-height: 420px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    fieldset {
        margin: 20px 0 0 0;
        padding: 0;
    }
}

.modal-buttons {
    .button,
    button {
        &.link {
            background: $color-tuic-bg;
        }
    }
}

.modal-trigger {
    @include font(13px, 1.5);
    margin: 0;
    padding-left: 9px;
    width: 100%;
    text-align: left;
}

.modal-trigger-label {
    padding-bottom: 2px;
}

.modal-info {
    @include font(15px);

    .icon {
        font-size: 17px;
        margin-right: 4px;
    }
}

.modal-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    &::before {
        display: inline-block;
        content: '✕';
        padding: 12px 16px;
        text-align: center;
        font-size: 21px;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-dark-blue-50;
    z-index: 3000;
}

.modal-inner-content {
  @extend .v-scroll;
}

@media #{$screen-tablet-desktop} {
    .modal {
        min-width: 40vw;
        width: auto;
    }

    .modal-trigger {
        margin-left: 24px;
    }

  @media screen and (max-height: 600px) {
    margin-top: 2vh;
  }

  @media screen and (max-height: 420px) {
    margin-top: 0;
  }

  .modal-inner-content {
    max-height: 50vh;
  }
}

@media #{$screen-mobile} {
    .modal {
        height: calc(100% - 50px);
        margin-top: 50px;
        overflow-y: auto;
    }
}

@media #{$screen-tablet} {
    .modal {
        height: calc(100% - 50px);
        margin-top: 50px;
        overflow-y: auto;
        max-width: 650px;
    }
}

@media #{$screen-desktop} {
    .modal {
        max-width: 860px;
    }
}
