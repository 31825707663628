.form-input {
    margin-bottom: 14px;
}

.form-input__label {
    display: block;
    margin-bottom: 2px;

    &.error {
        color: $color-tuic-red;
    }
}

.form-input__input {
    @include font(13px, 1.5);
    display: block;
    width: 100%;
    box-sizing: border-box;
    color: $color-tuic-font-default;
    border: 0;
    min-height: 18px;
    background: $color-tuic-blue-white;
    height: 36px;
    padding: 2px 8px;

    &:focus {
        border-style: none;
        border-color: $color-transparent;
        overflow: auto;
        outline: none;

        &::-webkit-input-placeholder {
            color:transparent;
        }

        &:-moz-placeholder {
            color:transparent;
        }
    }

    &.disabled {
        background: $color-tuic-blue-light;
        color: $color-tuic-blue-white;
        -webkit-text-fill-color: $color-tuic-blue-white;
        opacity: 1;
    }

    &.error {
        border: 1px solid $color-tuic-red-light;
    }

    &::placeholder {
        color: $color-tuic-blue-light;
    }
}

.form-input__remaining {
    @include font(11px, 1.5);
    float: right;
    width: 2em;
    text-align: right;
    margin-top: 2px;
}
