.badge-container {
  position: relative;
  & .badge {
    z-index: 10;
  }
}

.badge {
    /* @include font(12px, 1.5); */
    @include font(13px, 1.4);
    font-family: SourceSansPro;
    position: absolute;
    /* padding: 5px 20px; */
    padding: 5px 20px 5px 10px;
    top: -24px;
    left: -4px;
    width: 99%;
    box-sizing: border-box;
    color: $color-white;
    background: $color-tuic-blue-mid;
    box-shadow: 1px 2px $color-black-20;
    text-align: left;

    &.unavailable {
        background: $color-tuic-blue-light;
    }

    &.booked {
        background: $color-tuic-blue-dark !important;
    }

    &.bookable-info {
      background: $color-tuic-blue-mid;
    }

    &.right {
        padding: 9px 20px;
        top: -10px;
        left: auto;
        right: -4px;
        width: auto;
        box-shadow: -1px 2px $color-black-20;
    }

    &.inset {
        top: 3px;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -8px;
        height: 0;
        width: 0;
        border-width: 0 4px 8px 0;
        border-style: solid;
        border-color: $color-transparent $color-tuic-blue-dark $color-transparent $color-transparent;
    }

    &.right::before {
        left: auto;
        right: 0;
        border-width: 8px 4px 0 0;
        border-color: $color-tuic-blue-dark $color-transparent $color-transparent $color-transparent;
    }
}

@media #{$screen-tablet-desktop} {
    .badge {
        /* @include font(15px, 1.5); */
        @include font(18px, 1);
        font-family: SourceSansPro;
        top: -6px;
        left: -4px;
        padding: 9px 20px;
        width: auto;
    }
}

.operator {
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        content: ' ';
        z-index: 10;
    }

    &.external::before {
        background-color: #7da519;
    }

    &.tuic::before {
        background-color: #92d1d6;
    }

    &::after {
        box-sizing: border-box;
        position: absolute;
        bottom: -4px;
        right: 10px;
        width: 90px;
        height: 54px;
        border-radius: 8px;
        box-shadow: 1px -1px 0 0 rgba(0, 0, 0, 0.2);
        @include font(13px, 1.5);
        text-align: center;
        color: #ffffff;
        padding-top: 6px;
        z-index: 9;
    }

    &.external::after {
        background-color: #7da519;
        content: 'Vermittelter Ausflug';
    }

    &.tuic::after {
        background-color: #92d1d6;
        content: 'TUI Cruises Ausflug';
    }

    //default is at the bottom
    &.top-position {
      &::before {
        top: 0;
        bottom: unset;
      }
      &::after {
        top: -4px;
        bottom: unset;
        padding-top: 10px;
        box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
      }
    }
}
