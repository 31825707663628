.duration {
    &__icon {
        margin-right: 0.3em;
    }
}

@media #{$screen-tablet-desktop} {
    .duration--big {
        @include font(21px);
    }
}
