@mixin x-user-select ($value: none) {
    -webkit-touch-callout: $value;
    -webkit-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}

@mixin font($font-size: 15px, $line-height: 1.2) {
    font-family: SourceSansPro;
    font-size: $font-size;
    line-height: $line-height;
}

%disabled {
    pointer-events: none;
}

%list-style-dash {
    // Make the following lines align with first line
    margin-left: 8px;
    text-indent: -8px;
    list-style-type: none;

    &::before {
        content: '-';
        margin-right: 4px;
    }
}
