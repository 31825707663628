$icon-block-padding: 12px;
$icon-size: 48px;

.icn-block {
    display: block;
    margin-bottom: 20px;
    padding: $icon-block-padding;

    &.important {
        background-color: $color-tuic-yellow;
        border: 2px solid $color-white;
    }
}

.icn-block__iconholder {
    display: table-cell;
    font-size: $icon-size;
    line-height: 80px;
    vertical-align: top;
    padding-right: 30px;
    margin-left: -$icon-block-padding;

    svg {
        width: $icon-size;
        height: $icon-size;
    }
}

.icn-block__content {
    display: table-cell;
    vertical-align: top;
    width: 100%;

    > :last-child {
        margin-bottom: 0;
    }
}

.icn-block__content__more-link,
.icn-block__content__less-link {
    text-decoration: underline;
}

@media #{$screen-tablet-desktop}, print {
    $icon-size: 70px;

    .icn-block__iconholder {
        font-size: $icon-size;
        padding-right: 20px;

        svg {
            width: $icon-size;
            height: $icon-size;
        }
    }
}
