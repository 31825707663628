@keyframes loading-progress {
    0% {
        left: 0;
        width: 0;
    }

    50% {
        left: 25%;
        width: 50%;
    }

    100% {
        left: 100%;
        width: 0;
    }
}
