.infobox {
  margin-left: -$gutter-padding;
  margin-right: -$gutter-padding;
  background-color: $color-tuic-blue-light;
  display: block;
  margin-bottom: 20px;
  padding: 12px;

  &.small {
    padding: 10px;
  }

  &.important {
    background-color: $color-tuic-yellow;
    border: 2px solid $color-white;
  }

  &__iconholder {
    display: table-cell;
    font-size: 80px;
    line-height: 80px;
    vertical-align: top;
    padding-right: 20px;

    &.inline {
      font-size: 26px;
      line-height: 26px;
      padding-right: 12px;
      vertical-align: center;
    }
  }

  &__headline {
    display: table-cell;
  }

  &__text {
    padding-top: 8px;
  }

  &__content {
    display: table-cell;
    vertical-align: top;
    width: 100%;
    word-break: break-word;

    > :last-child {
      margin-bottom: 0;
    }

    &__more-link,
    &__less-link {
      text-decoration: underline;
    }

    > a[target=_blank] {
      margin-top: 10px;
    }

    .preview {
      height: 40px;
      overflow: hidden;
      position: relative;

      &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 24px;
        background: linear-gradient(
            rgba($color-tuic-blue-light, 0) 0%,
            rgba($color-tuic-blue-light, 1) 100%
        );
      }
    }

    ul {
      list-style: initial;
      margin: 0;
      padding: 5px 12px 0 17px;

      @media #{$screen-tablet-desktop} {
        padding: 10px 20px 0 17px;
      }

      li {
        margin-bottom: 5px;
        @media #{$screen-tablet-desktop} {
          margin-bottom: 10px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        span {
          position: relative;
          top: 1px;
        }
      }
    }

    .pin-info-container {
      margin-block-end: 15px;

      .id-card svg {
        width: 100%;
        max-width: 250px;
      }
    }
  }

  &__smaller {
    margin: 36px 0 24px 0;
  }

  .spacer-top {
    padding-top: 16px;
  }

  .travel-insurance {
    @include font(13px);

    .phone {
      white-space: nowrap;

      &.call {
        display: none;
      }

      &.text {
        display: inline;
      }
    }

    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .icon {
      &.shield {
        font-size: 40px;
        margin-right: 6px;
      }
    }

    img.component-teaser-insurance {
      width: 224px;
      height: auto;
      margin-top: -6px;
    }

    p {
      padding: 0 12px 0 0;
      margin: 4px 0 40px 0;
      line-height: 20px;
    }

    h3 {
      margin-bottom: 26px;
    }

    a.link {
      @include font(15px);
      white-space: nowrap;
      background-color: $color-tuic-blue-dark;
      padding: 12px 18px;
      color: white;
    }
  }

  &.small-icon {
    margin: 0 0 20px 0;

    .infobox__iconholder {
      font-size: 30px;
      line-height: 30px;
      vertical-align: middle;

      .icon.info {
        font-size: 30px;
      }
    }

    &.no-center-icon {
      .infobox__iconholder {
        vertical-align: top;
      }
    }
  }

  &.pin {
    margin: 0;
    padding: 20px 0;
    background-color: transparent;

    .infobox__iconholder {
      font-size: 36px;
      line-height: 36px;
      vertical-align: top;
      padding-right: 34px;
    }
  }

  &.svg {
    padding: 28px 28px 28px 20px;
    margin: 12px 0 40px 0;
    width: 100%;
    display: table;
    box-sizing: border-box;

    .row {
      display: table-row;
    }

    &.dual {
      margin-bottom: 12px;
    }

    &.wider {
      margin-left: -12px;
      width: 103%;
    }

    .icon-wrapper,
    strong {
      text-align: left;
    }

    .icon-wrapper {
      display: table-cell;
      vertical-align: middle;
      width: 38px;

      svg {
        fill: $color-tuic-blue-dark;
      }
    }

    &.no-center-icon {
      .icon-wrapper {
        vertical-align: top;
        padding-top: 8px;
      }
    }

    strong {
      vertical-align: middle;
      display: table-cell;
    }
  }

  .insurance-in-app {
    padding-top: 16px;
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    font-style: italic;
  }
}

@media screen and (max-width: 951px) {
  .infobox {
    &.svg {
      &.mobile-only {
        display: table;
      }

      &.desktop-only {
        display: none;
      }
    }
  }
}

@media #{$screen-tablet-desktop} {
  .infobox {
    padding: 20px;

    &.svg {
      padding-bottom: 23px;
    }

    &.small {
      padding: 12px;
    }
  }
}

@media #{$screen-desktop} {
  // Where is this needed? is it really needed?
  .infobox__iconholder.right {
    font-size: 40px;
    line-height: 40px;
    padding-right: 5px;
    left: -5px;
    position: relative;
  }

  .infobox {
    &.svg {
      &.mobile-only {
        display: none;
      }

      &.desktop-only {
        display: table;
      }
    }
  }
}

//@media #{$screen-mobile} {
//    .infobox {
//        .travel-insurance {
//            .row {
//                display: block;
//            }
//
//            p {
//
//            }
//
//            .phone {
//                &.call {
//                    display: inline;
//                }
//
//                &.text {
//                    display: none;
//                }
//            }
//        }
//    }
//}

@media #{$screen-tablet} {
  .infobox {
    .travel-insurance {
      .row {
        display: block;
      }

      .phone {
        &.call {
          display: inline;
        }

        &.text {
          display: none;
        }
      }
    }
  }
}

@media #{$screen-tablet-desktop} {
  .infobox {
    .travel-insurance {
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.equal {
          justify-content: space-between;
          align-items: flex-start;
        }
      }
    }
  }
}

@media #{$max-screen-tablet-desktop} {
  .infobox {
    .travel-insurance {
      .row {
        position: relative;
        width: 100%;
      }

      h2 {
        @include font(21px);
      }

      p {
        margin-bottom: 30px;
      }

      img.component-teaser-insurance {
        margin: 20px auto 0 auto;
        display: block;
      }

      a.link {
        @include font(13px);
        display: block;
        text-align: center;
      }

      .phone {
        &.call {
          display: inline;
        }

        &.text {
          display: none;
        }
      }
    }
  }
}
