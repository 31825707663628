.page-login {

  @media screen and (max-width: 643px) {
    .l-col.right {
      padding: 0;
      margin-inline: -12px;
    }
  }

  .page-login-form-wrapper {
    background-color: #B4E1F1;
    padding: 16px 10px;


    .page-login-form {
      .form-checkbox.big {
        min-height: 30px;

        .form-checkbox__indicator {
          height: 20px;
          width: 20px;
        }

        :checked + .form-checkbox__indicator {
          &::before {
            top: 1px;
          }
        }

        .form-checkbox__label {
          padding: 0 0 0 12px;
          font-size: 14px;
        }
      }

      .button {
        width: 100%;
      }
    }

    @media screen and (max-width: 643px) {
      padding: 16px 35px 16px 25px;
    }
  }

  .page-login-ul {
    margin: 0 0 12px;

    &.error {
      color: $color-tuic-red;

      li {
        @extend %list-style-dash;
      }
    }
  }

  .divider {
    height: 29px;
  }

  .button {
    width: 153px;
    text-align: center;
  }

  .teaser-block-top {
    height: 132px;
  }
}
