.travel-party-options {
    .radio-group,
    .select-group {
        display: none;
    }

    .select-group {
        @media screen and (max-width: 951px) {
            display: block;
        }
    }

    .radio-group {
        @media screen and (min-width: 952px) {
            display: block;
        }
    }

    &__item {
        margin-bottom: 6px;
        cursor: pointer;
    }

    .form-radio.light-blue {
        margin: 4px 4px 0 4px;
        background-color: $color-tuic-blue-white;
        @include font(15px);
        width: 100%;

        display: table;

        .radio-button,
        .radio-label {
            display: table-cell;
            vertical-align: middle;
        }

        .radio-button {
            width: 40px;
        }

        .radio-label {
            padding: 6px 12px 6px 0;
        }
    }

    .select-group {
        width: 272px;
        margin: 0 12px 0 -12px;

        .form-dropdown__select {
            padding-left: 14px;
        }

        @media screen and (max-width: 450px) {
            width: 100%;
            margin: 0;
        }
    }

    &.l-col {
        @media screen and (max-width: 450px) {
            margin-right: 0;
            margin-left: -12px;
        }
    }
}
