$radio-size: 12px;
$radio-inner-size: 4px;

.payment-options {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-around;
    flex-wrap: wrap;

    &__item {
        display: flex;
        width: 103px;
        flex-direction: column;
        text-align: center;
    }

    &__label {
        height: 2.9em;
        padding: 4px 10px;
        line-height: 1.4;
    }

    &__image {
        width: 100%;
        height: 49px;
        margin-bottom: 6px;
        font-size: 49px;
    }
}

.payment-options__item.disabled {
    .payment-options__label {
        margin: 0px -6px;
        padding: 4px 6px;
    }

    .form-radio.disabled {
        opacity: .5;
        cursor: default;

        .form-radio__input ~ .check {
            display: none;
        }

        .form-radio__input:checked ~ .check {
            display: none;
        }
    }
}

button.retry {
    margin-bottom: 14px;
    width: 100%;

    @media #{$screen-tablet-desktop} {
        width: auto;
    }
}
