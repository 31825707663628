.lockout-message {
    .red {
        margin: 0 0 12px;

        @include font(13px, 17px);
        color: $color-tuic-red;
    }

    .blue {
        margin-top: 20px;
        padding: 16px;
        border: 2px solid $color-tuic-blue-dark;
        @include font(13px, 18px);

        .minutes {
            background-color: $color-tuic-blue-light-bg;
            padding: 12px;
            @include font(24px, 27px);
            font-weight: bold;
            text-align: center;
            margin: 16px 0;
        }
    }
}