.menu {
    ul {
        position: absolute;
        top: 43px;
        width: 100%;
        border-bottom: 1px solid $color-cell-blue;
    }

    li {
        border-top: 1px solid $color-cell-blue;
        font-size: 18px;

        a {
            display: block;
            padding: 10px 12px 10px 32px;
            color: $color-cell-blue;
        }

        .active {
            color: $color-white;
        }
    }
}

.menu-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-white;
}
