.picture {
    display: block;
    max-width: 100%;

    source,
    img {
        width: 100%;
        border: 0;
    }
}
