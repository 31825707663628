.participant-category-list {
  background-color: $color-tuic-blue-light;
  display: block;
  margin-bottom: 8px;
  padding: 16px;

  &:last-child {
    margin-bottom: 16px;
  }

  & .participant-list {
    font-weight: bold;
  }

  ul {
    margin: 0;

    .date {
      margin-top: 8px;
    }
  }
}
