@font-face {
  font-family: 'tuic-icon';
    src:  url('../assets/fonts/tuic-icon.eot?vc3gbj');
    src:  url('../assets/fonts/tuic-icon.eot?vc3gbj#iefix') format('embedded-opentype'),
    url('../assets/fonts/tuic-icon.ttf?vc3gbj') format('truetype'),
    url('../assets/fonts/tuic-icon.woff?vc3gbj') format('woff'),
    url('../assets/fonts/tuic-icon.svg?vc3gbj#tuic-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tuic-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-tuic-icon::before {
  font-family: 'tuic-icon' !important;
}
.icon-id_document:before {
  content: "\e930";
}
.icon-spiegel:before {
  content: "\e92f";
}
.icon-ic-flight-questionaire-done:before {
  content: "\e92d";
}
.icon-ic-flight-questionaire-active:before {
  content: "\e92e";
}
.icon-pdf-tile:before {
  content: "\e92c";
}
.icon-ic-shippass:before {
  content: "\e925";
}
.icon-ic-payment:before {
  content: "\e926";
}
.icon-ic-payment-done:before {
  content: "\e927";
}
.icon-ic-manifest:before {
  content: "\e928";
}
.icon-ic-manifest-done:before {
  content: "\e929";
}
.icon-ic-healthy:before {
  content: "\e92a";
}
.icon-ic-healthy-done:before {
  content: "\e92b";
}
.icon-ic-smartphone-download:before {
  content: "\e924";
}

.icon-train:before {
  content: "\e922";
}
.icon-train-change:before {
  content: "\e923";
}

.icon-icon-anchor:before {
  content: "\e921";
  color: #253e6a;
}
.icon-vip-tariff:before {
  content: "\e920";
  color: $color-tuic-blue-dark;
}
.icon-call:before {
  content: "\e91f";
  color: $color-tuic-blue-dark;
}

.icon-info:before {
  content: '\e91d';
}
.icon-sicherheits-pin:before {
    content: '\e91e';
    color: $color-tuic-blue-dark;
}

.icon-twitter:before {
    content: '\e900';
    color: $color-white;
}
.icon-icon-barzahlung:before {
  content: '\e901';
  color: $color-tuic-blue-dark;
}
.icon-radio-on .path1:before {
  content: "\e902";
  color: rgb(124, 183, 203);
}
.icon-radio-on .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(38, 62, 106);
}
.icon-icon-pdf:before {
    content: '\e904';
    color: $color-tuic-blue-dark;
}
.icon-icon-table-reservation:before {
    content: '\e905';
    color: $color-tuic-blue-dark;
}
.icon-icon-spa-more:before {
    content: '\e906';
    color: $color-tuic-blue-dark;
}
.icon-icon-parking:before {
    content: '\e907';
    color: $color-tuic-blue-dark;
}
.icon-icon-internet:before {
    content: '\e908';
    color: $color-tuic-blue-dark;
}
.icon-icon-health-beauty:before {
    content: '\e909';
    color: $color-tuic-blue-dark;
}
.icon-icon-hairdresser:before {
    content: '\e90a';
    color: $color-tuic-blue-dark;
}
.icon-icon-fitness:before {
    content: '\e90b';
    color: $color-tuic-blue-dark;
}
.icon-icon-excursions:before {
    content: '\e90c';
    color: $color-tuic-blue-dark;
}
.icon-icon-excursion:before {
    content: '\e90d';
    color: $color-tuic-blue-dark;
}
.icon-arrow-up:before {
    content: '\e90e';
    color: $color-tuic-blue-dark;
}
.icon-arrow-down:before {
    content: '\e90f';
    color: $color-tuic-blue-dark;
}
.icon-airplane:before {
    content: '\e910';
    color: $color-tuic-blue-dark;
}
.icon-bus-transfer:before {
    content: '\e911';
    color: $color-tuic-blue-dark;
}
.icon-radio-off:before {
    content: '\e912';
  color: #7cb7cb;
}
.icon-seat:before {
    content: '\e913';
    color: $color-tuic-blue-dark;
}
.icon-shield:before {
    content: '\e914';
    color: $color-tuic-blue-dark;
}
.icon-take-off:before {
    content: '\e915';
    color: $color-tuic-blue-dark;
}
.icon-ic-online-check-in:before {
    content: '\e916';
    color: $color-tuic-blue-dark;
}
.icon-icon-gruppe:before {
    content: '\e917';
    color: $color-tuic-blue-dark;
}
.icon-icon-kontaktdaten:before {
    content: '\e918';
    color: $color-tuic-blue-dark;
}
.icon-icon-print:before {
    content: '\e919';
    color: $color-tuic-blue-dark;
}
.icon-icon-program:before {
    content: '\e91a';
    color: $color-tuic-blue-dark;
}
.icon-icon-smartphone-download:before {
    content: '\e91b';
    color: $color-tuic-blue-dark;
}
.icon-icon-zahlungsart:before {
    content: '\e91c';
    color: $color-tuic-blue-dark;
}
