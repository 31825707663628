.account-header {
  background: url('../assets/img/c/header/divider.png') no-repeat center bottom;

  @media (max-width: 879px) {
    background: none;
  }

  a,
  button {
    -webkit-tap-highlight-color: transparent;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    color: $color-tuic-blue-dark;

    :hover {
      color: $color-tuic-blue-dark;
    }
  }
}

main.has-account-header {
  padding-top: 40px;

  @media (max-width: 879px) {
    padding-top: 79px;
  }
}
