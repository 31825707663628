.headline {
    margin: 0 0 20px;

    .icon {
        @include font(21px * 1.2, 1);
        float: left;
        margin-right: 10px;
    }

    &__h1 {
        @include font(21px, 1.2);
        margin: 0 0 0.2em;
    }

    &__h2 {
        @include font(15px, 1.2);
        margin: 0 0 0.2em;
    }

    &__h3 {
        @include font(15px, 1.2);
        margin: 0;
    }

    &__h3--italic {
        font-style: italic;
    }
}

@media #{$screen-tablet-desktop} {
    .headline {
        .icon {
            @include font(30px * 1.2, 1);
        }

        &__h1 {
            @include font(30px, 1.2);
        }

        &__h2 {
            @include font(21px, 1.2);
        }

        &__h3 {
            @include font(21px, 1.2);
        }

        &__h3--italic {
            font-style: italic;
        }
    }
}

@media print {
    .headline {
        &__h1 {
            font-weight: bold;
        }

        &__h2 {
            font-weight: bold;
        }

        &__h3 {
            font-weight: bold;
        }
    }
}
