.brochure {
    margin-top: 20px;

    a {
        text-decoration: underline;
        position: relative;
        padding-right: 30px;
    }

    .pdf {
        position: absolute;
        right: 0;
        top: -6px;
        font-size: 22px;
        @extend .icon-icon-pdf;
    }
}

@media #{$screen-desktop} {
    .brochure {
        margin-top: 0;
    }
}