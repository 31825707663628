@font-face {
    font-family: 'SourceSansPro';
    font-display: block;
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/SansPro/SourceSansPro-Regular.ttf?canv3g') format('truetype');
}

@font-face {
    font-family: 'SourceSansPro';
    font-display: block;
    font-style: normal;
    font-weight: bold;
    src: url('../assets/fonts/SansPro/SourceSansPro-SemiBold.ttf?canv3g') format('truetype');
}



@font-face {
    font-family: 'fontcustom';
    src: url('../assets/fonts/fontcustom.eot');
    src: url('../assets/fonts/fontcustom.eot#iefix') format('embedded-opentype'),
        url('../assets/fonts/fontcustom.woff') format('woff'),
        url('../assets/fonts/fontcustom.ttf') format('truetype'),
        url('../assets/fonts/fontcustom.svg#fontcustom') format('svg');
    font-weight: normal;
    font-style: normal;
}
