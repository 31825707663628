.daily-catalog-tiles {
    &__item {
        margin-bottom: 40px;

        &__title {
            @include font(21px);
            margin-bottom: 20px;
        }
    }
}
