.price-types {
  margin-top: 28px;

  &:first-child {
    margin-top: 0;
  }

  .label {
    @include font(20px);
    margin-bottom: 4px;

    &.small {
      @include font(15px);
      font-weight: bold;
      text-align: left;
    }
  }

  .price-type {
    border-bottom: 1px solid $color-tuic-border-dark;
    margin: 8px 0;

    .row {
      display: flex;
      padding: 2px 0 6px 0;

      .text {
        flex: 1;
        text-align: left;
      }

      &:first-child {
        padding-top: 6px;
      }

      &.big {
        @include font(18px);
        padding: 6px 0 10px 0;
      }
    }
  }

  @media screen and (max-width: 340px) {
    .label {
      @include font(18px);
    }

    .price-type {
      .row {
        &.big {
          @include font(16px);
        }
      }
    }
  }
}
