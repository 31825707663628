$tile-base-padding: 12px;
$tile-base-margin: 12px;
$tile-size-mobile: 68px;

.tile-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 12px;

    .tile {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &::before {
        display: table;
        content: '';
    }
}

.tile {
    display: flex;
    flex-direction: row;

    position: relative;
    margin: 0 $tile-base-margin $tile-base-margin 0;
    padding: $tile-base-padding;
    min-height: $tile-size-mobile;
    color: inherit;
    text-decoration: none;
    background: $color-tuic-blue-white;
    transition: filter 50ms ease-in-out;

    &.disabled {
        @extend %disabled;
        background: $color-tuic-blue-light;
        color: $color-tuic-blue-white;
    }

    &.has-badge {
        margin-top: 30px;

        &:first-of-type {
            margin-top: 24px;
        }
    }

    &.no-padding {
        padding: 0 $tile-base-padding;
    }

    @media (max-width: 643px) {
        display: flex;
    }
}

a {
    &.tile {
        svg {
            fill: currentColor;
        }
    }

    &.tile:hover {
        color: $color-white;
        background: $color-tuic-blue-light-hover;
        // if it is an SVG:
        i {
            filter: brightness(100);
        }

        svg {
            fill: white;
        }
    }

  &.tile.disabled {
    i:before {
      color: $color-tuic-blue-white;
    }
  }
}

div.tile.disabled {
  i.icon:before {
    color: $color-tuic-blue-white;
  }
}

.tile__image,
.tile__icon {
    display: table-cell;
    width: $tile-size-mobile;
    min-width: $tile-size-mobile;
    height: $tile-size-mobile;
    line-height: 1;
    text-align: center;
}

.tile__icon {
    font-size: $tile-size-mobile;
    line-height: 64px;

    .image-icon & {
        background: $color-tuic-bg;
    }
}

.tile__details {
  display: table-cell;
  vertical-align: middle;

  @media (min-width: 644px) {
    & .tile__bonus {
      display: block;
      text-align: left;
      padding: 0 0 0 12px;
    }
  }

  @media (max-width: 643px) {
    flex: 1;
    align-self: center;

    & .tile__bonus {
      width: 100%;
    }
  }
}

.tile__text {
    display: table-cell;
    vertical-align: middle;
    padding: 12px;

    @media (max-width: 643px) {
        flex: 1;
        align-self: center;
    }
}

.tile__text-container {
  display: table-cell;
  vertical-align: middle;
}

.tile__bonus {
    font-weight: bold;
    text-align: right;
    display: table-cell;
    vertical-align: middle;
    padding: 0 12px 0 0;

    svg {
        margin: 0 6px -8px 0;

        @media (max-width: 386px) {
            transform: scale(0.75);
            margin-right: 2px;
            margin-bottom: -10px;
        }
    }

    @media (max-width: 386px) {
        font-size: 10px;
    }

    @media (max-width: 340px) {
        margin-top: -6px;
        font-size: 8px;
    }

    @media (max-width: 643px) {
        flex: 1;
        align-self: center;
    }
}

.tile__clamp {
    @include font(15px);
    max-height: 4.8em;
    overflow: hidden;

    .small & {
        @include font(12px);
    }

    @media (max-width: 400px) {
        font-size: 11px;
        .small & {
            font-size: 9px;
        }
    }

    .long-title & {
        @include font(15px, 1.5);
        max-height: none;
    }
    span {
        display: block;
    }
}

@media #{$screen-tablet-desktop} {
    $tile-size-single: 118px;
    $tile-size-double: $tile-size-single * 2 + $tile-base-padding * 2 +
        $tile-base-margin;

    .tile-group {
        display: inline-block;
        float: left;
        width: 308px;
        margin-bottom: 0;

        &:last-child {
            width: auto;
        }

        .tile {
            &:last-child {
                margin-bottom: $tile-base-margin;
            }
        }
    }

    .container {
        .tile-group {
            &:last-child {
                width: 308px;
            }
        }
    }

    .tile {
        display: block;
        float: left;
        width: $tile-size-double;
        height: $tile-size-single;

        &.has-badge {
            margin-top: 0;

            // because it specified like this outside of media query as well
            &:first-of-type {
                margin-top: 0;
            }
        }

        &.full {
            height: $tile-size-double;

            .tile__image {
                width: 100%;
                height: 162px;
                display: block;
            }

            .tile__icon {
                display: block;
                font-size: 120px;
            }

            .tile__details {
              display: block;
            }

            .tile__text {
                display: block;
                padding: 10px 0 0 0;
            }

            .tile__clamp {
                max-height: 2.8em;

                &.has-icon {
                    text-align: center;
                }
            }

            &.is-highlight {
              overflow: hidden;
              .tile__clamp {
                max-height: 4.8em;
              }
            }

            .tile__bonus {
                position: absolute;
                bottom: 86px;
                right: 4px;
                font-size: 14px;
            }
        }
    }

    .tile__image {
        width: $tile-size-single;
        height: $tile-size-single;

        .picture {
            // Use the source image for bigger screens only
            .landscape {
                display: none;
            }

            .square {
                display: inline;
            }
        }
    }

    .tile__icon {
        width: 104px;
        height: 120px;
        margin: 43px 76px 0;
        font-size: 80px;
        line-height: $tile-size-single;

        .image-icon & {
            margin: 0;
            padding: 28px 76px 15px;
        }
    }

    .tile__clamp {
        @include font(21px);
    }
}

@media #{$screen-desktop} {
    $tile-size-single: 118px;
    $tile-size-double: $tile-size-single * 2 + $tile-base-padding * 2 +
        $tile-base-margin;

    .tile-group:last-child {
        width: 308px;
    }
}
