.rating {
    white-space: nowrap;
}

.rating__dot {
    display: inline-block;
    height: 12px;
    width: 12px;
    overflow: hidden;
    background: currentColor;
    margin-right: 3px;
    opacity: 0.2;
    position: relative;

    &::after {
        position: absolute;
        font-family: fontcustom;
        font-size: 9px;
        color: $color-white;
        width: 12px;
        height: 12px;
        top: 1px;
        left: 0;
        line-height: 12px;
        text-align: center;
        display: inline-block;
    }

    &.easy::after {
        content: '\f104';
    }

    &.easy,
    &.active {
        opacity: 1;
    }
}
