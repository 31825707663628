div.popup, div.popup-background {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;

  &.popup-background {
    opacity: .8;
    background-color: #253E6A;
  }

  .popup-inner {
    position: relative;
    max-width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    opacity: 1;
    z-index: 101;
    background-color: #98D4E3;
    margin: 0 auto;
    top: 25vh;
    gap: 32px;

    .popup-header {
      color: #263E6A;
      font-size: 21px;
      line-height: 27px;
    }

    .popup-buttons {
      display: flex;
      justify-content: space-between;

      > .button.dark {
        width: unset;
      }
    }
  }
}
