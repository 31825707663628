.description {
    ul, ol {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 22px;
    }

    ol {
        list-style-type: decimal;
    }
}