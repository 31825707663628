@keyframes blink {
  50% {
    opacity: 0;
  }
}

.pin-input {
  input {
    position: absolute;
    z-index: -1;
    top: -10000px;
    left: -10000px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .pin-block {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    background-color: $color-tuic-blue-white;
    margin-right: 4px;

    border: 1px solid transparent;

    &.active {
      border: 1px solid $color-tuic-blue-dark;

      &::after {
        content: ' ';
        height: 24px;
        border: solid 0.5px $color-tuic-blue-dark;
        position: absolute;
        top: 6px;
        left: 18px;
        animation: blink 1.5s step-start 0.5s infinite;
      }
    }

    &.wrong-pin {
      border: 1px solid $color-tuic-red;
    }

    strong {
      width: inherit;
      height: inherit;
      display: table-cell;
      text-align: center;
      vertical-align: middle;

      &.dot {
        font-size: 22px;
      }
    }

    &:nth-of-type(4) {
      margin-right: 0;
    }
  }
}

.pin-info {
  .red {
    color: $color-tuic-red;
  }
}

.modal-overlay.pin {
  background-color: $color-dark-blue-80;

  .modal-content {
    @media #{$screen-desktop} {
      max-width: 520px;
      margin: 0 auto;
    }

    h2 {
      .icon.info {
        margin-right: 6px;
        font-size: 26px;
      }
    }

    h3 {
      .icon.info {
        margin-left: 6px;
        font-size: 18px;
      }
    }

    .modal-buttons {
      button {
        transition: background-color 0.05s ease;
      }
    }

    .pin-input-container,
    .pin-info-container {
      margin: 20px auto 20px auto;
      position: relative;
    }

    .pin-input-container {
      width: 170px;
      text-align: right;
    }

    .pin-info-container {
      margin-top: 26px;
      text-align: center;

      .id-card svg {
        width: 100%;
        max-width: 250px;
      }
    }

    .toggle-pin {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 13px;
      line-height: 2;
      color: $color-tuic-blue-dark;
      text-decoration: underline;
      background-color: transparent;

      &.disabled,
      &[disabled] {
        background-color: transparent;
        opacity: 0.5;
      }
    }

    .modal-inner-content {
      max-height: 90vh;
    }
  }
}
