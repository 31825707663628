@mixin svg-icon ($icon) {
    &::before {
        content: ' ';
        width: 1em;
        height: 1em;
        background-image: url($icon);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 1em;
    }
}

@mixin png-icon ($icon) {
    &::before {
        content: ' ';
        width: 100%;
        height: 100%;
        background-image: url($icon);
        background-repeat: no-repeat;
        background-position: center center;
    }
}

@mixin bg-img ($icon) {
    background-image: url($icon);
    background-repeat: no-repeat;
    background-position: center center;
}

.icon {
    &::before {
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        speak: none;
        font-size: 1em;
        line-height: 1;
        vertical-align: baseline;
        text-transform: none;
        font-family: fontcustom;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
    }

    &.manifest,
    &.manifest-done,
    &.payment,
    &.payment-done,
    &.check-in,
    &.healthy,
    &.healthy-done,
    &.flight-questionaire,
    &.flight-questionaire-done {
        @extend .font-tuic-icon;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: $color-tuic-blue-dark;
    }

    &.manifest {
        @extend .icon-ic-manifest;
    }

    &.manifest-done {
        @extend .icon-ic-manifest-done;
    }

    &.payment {
        @extend .icon-ic-payment;
    }

    &.payment-done {
        @extend .icon-ic-payment-done;
    }

    &.check-in {
        @extend .icon-ic-online-check-in;
    }

    &.healthy {
        @extend .icon-ic-healthy;
    }

    &.healthy-done {
        @extend .icon-ic-healthy-done;
    }

    &.flight-questionaire {
        @extend .icon-ic-flight-questionaire-active;
    }

    &.flight-questionaire-done {
        @extend .icon-ic-flight-questionaire-done;
    }

    &.call {
        @extend .font-tuic-icon;
        @extend .icon-call;
    }

    &.train {
        @extend .font-tuic-icon;
        @extend .icon-train;
        color: $color-tuic-blue-dark;
    }

    &.train-change {
        @extend .font-tuic-icon;
        @extend .icon-train-change;
        color: $color-tuic-blue-dark;
    }

    &.airplane {
        @extend .font-tuic-icon;
        @extend .icon-airplane;
    }

    &.take-off {
        @extend .font-tuic-icon;
        @extend .icon-take-off;
    }

    &.seat {
        @extend .font-tuic-icon;
        @extend .icon-seat;
    }

    &.shield {
        @extend .font-tuic-icon;
        @extend .icon-shield;
    }


    &.print {
        @extend .font-tuic-icon;
        @extend .icon-icon-print;
    }

    &.mobile-download {
        @extend .font-tuic-icon;
        @extend .icon-icon-smartphone-download;
    }

    &.pdf {
        @extend .font-tuic-icon;
        @extend .icon-icon-pdf;
    }

    &.pdf-tile {
      @extend .font-tuic-icon;
      @extend .icon-pdf-tile;
    }

    &.fixed {
        &.print,
        &.mobile-download,
        &.pdf {
            font-size: 75%;

            &::before {
                vertical-align: text-top;
            }
        }
    }

    &.vip-tariff {
      @extend .font-tuic-icon;
      @extend .icon-vip-tariff;
    }

    &.contactdata {
        @extend .font-tuic-icon;
        @extend .icon-icon-kontaktdaten;
    }

    &.people {
        @extend .font-tuic-icon;
        @extend .icon-icon-gruppe;
    }

    &.cash {
        @extend .font-tuic-icon;
        @extend .icon-icon-barzahlung;
    }

    &.fitness {
        @extend .font-tuic-icon;
        @extend .icon-icon-fitness;
    }

    &.program {
        @extend .font-tuic-icon;
        @extend .icon-icon-program;
    }

    &.health-beauty {
        @extend .font-tuic-icon;
        @extend .icon-icon-health-beauty;
    }
    &.spiegel {
        @extend .font-tuic-icon;
        @extend .icon-spiegel;
    }

    &.spa-more {
        @extend .font-tuic-icon;
        @extend .icon-icon-spa-more;
    }

    &.hairdresser {
        @extend .font-tuic-icon;
        @extend .icon-icon-hairdresser;
    }

    &.internet {
        @extend .font-tuic-icon;
        @extend .icon-icon-internet;
    }

    &.excursion {
        @extend .font-tuic-icon;
        @extend .icon-icon-excursion;
    }

    &.anchor {
      @extend .font-tuic-icon;
      @extend .icon-icon-anchor;
    }

    &.table-reservation {
        @extend .font-tuic-icon;
        @extend .icon-icon-table-reservation;
    }

    &.parking {
        @extend .font-tuic-icon;
        @extend .icon-icon-parking;
    }

    &.alldecks::before {
        content: '\f111';
    }

    &.alert::before {
        content: '\f12e';
    }

    &.back {
        font-size: 27px;

        &::before {
            content: '\f10e';
        }
    }

    &.bookingdetails::before {
        content: '\f117';
    }

    &.suitcase::before {
        content: '\f121';
    }

    &.checked::before {
        content: '\f104';
    }

    &.clock::before {
        content: '\f105';
    }

    &.contact::before {
        content: '\f118';
    }

    &.dropdown::before {
        content: '\f10f';
    }

    &.envelope {
        font-size: .8em;
        line-height: 1.2;

        &::before {
            content: '\f12f';
        }
    }

    &.environment::before {
        content: '\f12d';
    }

    &.faq {
        font-size: .85em;

        &::before {
            content: '\f122';
        }
    }

    &.fbGuests {
        font-size: 0.83em;

        &::before {
            content: '\f125';
            margin-right: -.19em;
        }
    }

    &.idcard::before {
        content: '\f11a';
    }
    &.iddocument {
      @extend .font-tuic-icon;
      @extend .icon-id_document;
      @media #{$screen-desktop} {
        font-size: 150px;
      }
    }

    &.itinerary::before {
        content: '\f103';
    }

    &.info {
        &::before {
            content: '\f108';
        }

        &.manifest::before {
            line-height: 120%;
        }
    }

    &.infoShip::before {
        content: '\f124';
    }

    &.infoTravel::before {
        content: '\f121';
    }

    &.logoMeinschiff::before {
        content: '\f11f';
    }

    &.logoTuic::before {
        content: '\f120';
    }

    &.logout {
        font-size: 22px;

        &::before {
            content: '\f110';
        }
    }

    &.nutrition {
        &::before {
            content: '\f11b';
        }

        &.manifest::before {
            line-height: 140%;
        }
    }

    &.profile {
        &::before {
            content: '\f119';
        }

        &.manifest::before {
            line-height: 140%;
        }
    }

    &.visa::before {
        content: '\f123';
        margin-right: -.2em;
    }

    &.american-express-option {
        @include bg-img('../assets/img/pay-american-express.png');
    }

    &.visa-option {
        @include bg-img('../assets/img/pay-visa.png');
    }

    &.sepa-option {
        @include bg-img('../assets/img/pay-ec-card.png');
    }

    &.mastercard-option {
        @include bg-img('../assets/img/pay-mastercard.png');
    }
}


@media #{$screen-tablet-desktop} {
    .icon.environment {
        line-height: 1.2;
        font-size: 1.08em;
    }

    .icon.infoShip {
        font-size: 1.08em;
    }
}
