.teaser-tile {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 -12px 12px -12px;
    padding-left: 5px;
    min-height: 109px;
    color: inherit;
    text-decoration: none;
    background: $color-tuic-blue-white;

    &:hover {
        color: $color-white;
        background: $color-tuic-blue-light-hover;
    }

    &.disabled {
        @extend %disabled;
        background: $color-tuic-blue-light;
        color: $color-tuic-blue-white;
    }
}

.teaser-tile__icon {
    display: table-cell;
    text-align: center;
    max-width: 80px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    padding: 15px 0 0 12px;
}

.teaser-tile__text {
    padding: 17px 12px 17px 12px;
    display: table-cell;
    vertical-align: middle;
}

.teaser-tile__clamp {
    @include font(21px);
    max-height: 2.4em;
    overflow: hidden;
}
