.breadcrumb {
    display: none;
    margin-bottom: 37px;
    font-weight: bold;
}

.breadcrumb__crumb {
    @include font(15px, 36px);
    color: inherit;
    background: $color-tuic-blue-white;
    display: inline-block;
    padding: 0 9px 0 25px;
    margin: 0 3px 3px 0;
    height: 36px;
    position: relative;
    text-decoration: none;
    vertical-align: middle;
    flex-shrink: 0;
    min-width: 0;

    > span {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &::before {
        position: absolute;
        top: 0;
        content: '';
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 18px 0 18px 12px;
        border-color: $color-transparent $color-transparent $color-transparent $color-tuic-bg;
        z-index: 1;
    }

    &::after {
        position: absolute;
        top: 0;
        content: '';
        left: 100%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 18px 0 18px 12px;
        border-color: $color-transparent $color-transparent $color-transparent $color-tuic-blue-white;
        z-index: 2;
    }

    &:hover {
        background: $color-tuic-blue-light-hover;
        color: $color-white;
    }

    &:hover::after {
        border-color: $color-transparent $color-transparent $color-transparent $color-tuic-blue-light-hover;
    }

    &:first-child {
        padding-left: 14px;
    }

    &:first-child::before {
        border: 0;
    }

    &:last-child {
        background: $color-tuic-blue-light-hover;
        color: $color-white;
        pointer-events: none;
        flex-shrink: 1;
    }

    &:last-child::after {
        border-color: $color-transparent $color-transparent $color-transparent $color-tuic-blue-light-hover;
    }
}

@media #{$screen-tablet-desktop} {
    .breadcrumb {
        display: flex;
    }
}

@media print {
    .breadcrumb {
        display: none;
    }
}
