.form-checkbox {
  cursor: pointer;
  display: block;
  margin-bottom: 12px;
  padding-left: 1.6em;
  position: relative;

  &.error {
    color: $color-tuic-red;
  }
}

.form-checkbox__input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.form-checkbox__indicator {
  background: $color-tuic-blue-white;
  color: $color-white;
  display: block;
  font-family: fontcustom;
  height: 1.1em;
  left: 0;
  overflow: hidden;
  position: absolute;
  //@include x-user-select;
  text-align: center;
  top: 3px;
  width: 1.1em;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &::before {
    content: '';
    font-size: 75%;
    line-height: 150%;
    vertical-align: top;
  }

  .error > & {
    border: 1px solid $color-tuic-red-light;
  }

  :checked ~ & {
    color: $color-tuic-blue-dark;

    &::before {
      content: '\f104';
    }
  }

  :disabled ~ & {
    background: $color-tuic-blue-light;
  }
}

.form-checkbox-fakegroup {
  .form-checkbox {
    padding-left: 3.2em;
    user-select: none;
  }

  .form-checkbox__indicator {
    border: 1px solid $color-dark-blue-80;
    align-self: stretch;
    background: $color-tuic-blue-light;
    box-sizing: border-box;
    color: #fff;
    display: block;
    font-family: fontcustom;
    font-size: 1.3em;
    line-height: 34px;
    min-height: 34px;
    min-width: 34px;
    padding-top: 7px;
    text-align: center;
    vertical-align: middle;
    width: 34px;
    -ms-flex-item-align: stretch;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  label {
    cursor: pointer;
    display: flex;
  }

  :checked + .form-checkbox__indicator {
    background: $color-tuic-blue-dark;

    &::before {
      content: '\f104';
      left: .5px;
      position: relative;
      top: 1px;
    }
  }
}

.form-checkbox.big {
  min-height: 50px;

  .form-checkbox__label {
    display: table-cell;
    vertical-align: middle;
    min-height: 36px;
    padding: 0 0 0 30px;
  }

  .form-checkbox__indicator {
    font-family: fontcustom;
    top: 0;
    width: 36px;
    height: 36px;
    background: $color-tuic-blue-light;
  }

  :checked + .form-checkbox__indicator {
    background: $color-tuic-blue-light-hover;
    color: white;

    &::before {
      font-size: 1em;
      content: '\f104';
      position: relative;
      top: 9px;

    }

  }
}

