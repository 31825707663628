.content-box {
    margin-left: -$gutter-padding;
    margin-right: -$gutter-padding;
    background-color: $color-tuic-blue-light;
    display: block;
    margin-bottom: 20px;
    padding: 12px;

    &__title {
        @include font(18px);
        padding-bottom: 6px;

        svg {
            // Fix positioning of icon
            margin-top: -4px;
            margin-right: 8px;
            vertical-align: middle;
        }
    }

    &__content {
        p:last-child {
            margin-bottom: 0;
        }
    }

    &--partial-checkin {
        margin-top: 40px;
    }

    &__smaller {
        margin-left: 0;
        margin-right: 0;
    }
}

@media #{$screen-tablet-desktop} {
    .content-box {
        padding: 20px;
    }
}
