.portal-intro {
  margin-bottom: 30px;

  .icon-headline {
    // TUICUNIT-981
    margin-bottom: 14px;

    .icon,
    h2 {
      display: table-cell;
      vertical-align: middle;
    }

    .icon {
      font-size: 26px;
    }

    h2 {
      padding: 2px 0 0 8px;
    }
  }

  .accordion {
    // TUICUNIT-981
    margin-top: 20px;

    .accordion-item {
      padding-left: 6px;

      .accordion-item__header {
        padding-right: 24px;
      }
    }
  }
}
