.form-fieldset {
    padding: 0;
    border: 0;
    margin: 0 0 12px 0;

    > *:last-child {
        margin-bottom: 0 !important;
    }
}

.form-checkbox-fakegroup {
    .form-fieldset {
        margin: 0 0 12px 0;
    }
}


@media #{$screen-tablet-desktop} {
    .double .form-fieldset > *.double {
        float: left;
        margin-right: 6px;
        box-sizing: border-box;
        width: 287px;
    }
}

@media #{$screen-desktop} {
    .double .form-fieldset.double {
        margin-right: -12px;
    }

    .double .form-fieldset.double::after {
        content: ' ';
        display: table;
        clear: both;
    }

    .double .form-fieldset > *.double:nth-child(odd) {
        margin-bottom: 0 !important;
    }
}

@media #{$screen-tablet} {
    .form-fieldset {
        &.double {
            margin-right: -12px;

            &::after {
                content: ' ';
                display: table;
                clear: both;
            }
        }

        > *.double {
            float: left;
            margin-right: 6px;
            box-sizing: border-box;
            width: 287px;

            &:nth-child(odd) {
                margin-bottom: 0 !important;
            }
        }
    }
}
