.youtube-teaser {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: 0;
    margin: 0 0 12px;

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background: $color-tuic-bg;
        bottom: 0;
    }

    > iframe {
        width: 100%; // !important;
        height: 100%; // !important;
        position: absolute;
        top: 0;
        left: 0;
    }
}
