.benefit-list {
    &__item {
        display: flex;
        margin-bottom: 12px;

        > .svg {
            margin-right: 6px;
        }
    }
}
