.service-info {
    display: table;
    width: 80%;
}

.service-info__row {
    display: table-row;
}

.service-info__col {
    display: table-cell;
}
