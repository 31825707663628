.l-block {
    margin-right: -12px;
    margin-left: -12px;
}

.l-col {
    box-sizing: border-box;
    margin: 0 12px 0 0;
    padding: 0 12px;

    &.debug {
        background: $color-white-20;
        min-height: 90px;
        outline: 1px dashed $color-dark-blue-50;
    }
}

.l-right {
    text-align: right;
}

.l-center {
    text-align: center;
}

.l-row {
    margin: 0 -24px 20px -12px;

    &::after {
        content: ' ';
        display: table;
        clear: both;
    }

    &.group {
        margin-bottom: 0;
    }
}

.l-spread {
    vertical-align: bottom;
    display: table;
    width: 100%;

    &.middle {
        vertical-align: middle;
    }

    &.top {
        vertical-align: top;
    }
}

.l-spread_left {
    display: table-cell;
    text-align: left;
    padding-right: 6px;
    vertical-align: inherit;
}

.l-spread_center {
    display: table-cell;
    text-align: center;
    padding: 0 6px;
    vertical-align: inherit;
}

.l-spread_right {
    display: table-cell;
    text-align: right;
    padding-left: 6px;
    vertical-align: inherit;
}

.l-tiles {
    margin: 0 -24px 28px -12px;

    &::after {
        content: ' ';
        display: table;
        clear: both;
    }

    &::before {
        display: table;
        content: '';
    }

    &.napp {
        @media #{$max-screen-tablet-desktop} {
            margin: 0;
        }
    }
}

.l-form-frame {
    background: $color-tuic-blue-light;
    padding: 9px 12px 12px;
    margin-bottom: 12px;
    margin-left: -12px;
    margin-right: -12px;

    > :last-child {
        margin-bottom: 0;
    }
}

.main-app {
    .l-mod {
        margin-bottom: 40px;
    }

    .l-mod-sub {
        margin-bottom: 20px;
    }
    .l-col-space {
        margin-bottom: 0;
    }
}

@media #{$screen-tablet-desktop} {
    .l-col {
        float: left;
        width: $column-width;

        &.double {
            width: $max-width-tablet;
        }

        &.large {
          float: unset;
          width: $max-width-tablet;
        }

        &.right,
        &.left {
            clear: both;
            width: $max-width-tablet;
        }
    }
}

@media #{$screen-desktop} {
    .l-col {
        &.double {
            float: left;
        }

        &.large {
            width: $max-width-desktop;
        }

        &.right {
            width: $column-width;
            float: right;
            clear: none;
        }

        &.left {
            width: $column-width;
            float: left;
            clear: none;
        }
    }
    .main-app .l-col-space {
        margin-bottom: 24px;
    }
}
