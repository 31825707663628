$color-white: #fff;
$color-white-20: rgba(255, 255, 255, 0.2);
$color-white-80: rgba(255, 255, 255, 0.8);
$color-black: #000;
$color-black-60: rgba(0, 0, 0, 0.6);
$color-black-20: rgba(0, 0, 0, 0.2);
$color-transparent: transparent;
$color-cell-blue: #0a6083;
$color-dark-blue-50: rgba(37, 62, 106, 0.5);
$color-dark-blue-80: rgba(37, 62, 106, 0.8);

$color-tuic-blue-dark: #253e6a;
$color-tuic-blue-white: #ceebf4;
$color-tuic-blue-light: #7ab7cc;
$color-tuic-blue-light-bg: #7cb7cb;
$color-tuic-blue-light-hover: #72cae8;
$color-tuic-blue-mid: #2692d8;
$color-tuic-red: #e01c1c;
$color-tuic-red-light: #cc7a7a;
$color-tuic-yellow: #f5a70f;
$color-tuic-blue-info: #5f99b1;

$color-tuic-bg: #85cee4;
$color-tuic-font-default: $color-tuic-blue-dark;
$color-tuic-border-dark: $color-tuic-blue-light;
$color-tuic-border-light: #addde9;

$color-print-grey: #cccccc;
$color-print-grey-light: #f2f2f2;
$color-seat-info : #6b7994;

$color-input-radio-active: #e8ecf2;

$font-size-large: 20px; // deprecated

$column-width: 296px;
$max-width-tablet: 604px;
$max-width-desktop: 912px;

$screen-mobile: screen and (max-width: 320px);
$screen-tablet: screen and (min-width: 644px) and (max-width: 951px);
$screen-tablet-desktop: screen and (min-width: 644px);
$screen-desktop: screen and (min-width: 952px);
$max-screen-tablet-desktop: screen and (max-width: 644px);
$screen-no-desktop: screen and (max-width: 951px);
$gutter-padding: 12px;
