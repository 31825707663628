.hero-image {
    margin-left: -12px;
    margin-right: -12px;
}

.hero-image__placeholder {
    background: $color-tuic-blue-light;
    position: relative;
    padding-top: 51%;
    width: 100%;
    overflow: hidden;
}

.hero-image__image {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: 100%;
}
