// Note:
//    The Design uses a different font than the WebFrontend.
//    Temporary Solution: Reduce the font-size 2px to fit the Design-Font
//    TODO: Agree with the customer which font to use
//
.login-teaser {
  font-family: SourceSansPro;
  margin-bottom: 52px;
  padding: 48px 0 93px 0;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  max-width: 1034px;
  position: relative;

  .new-label {
    position: absolute;
    top: -24px;
    right: 50px;
    padding: 4px 28px 2px 23px;
    background-color: #2692D8;
    color: white;
    font-size: 28px;
    font-weight: bold;

    &::before,
    &::after {
      content: ' ';
      position: absolute;
      border-style: solid;
      width: 0;
      height: 0;
    }

    &::before {
      top: 0;
      left: -5px;
      border-width: 48px 8px 0 8px;
      border-color: #2692D8 transparent transparent transparent;
    }

    &::after {
      bottom: 0;
      left: -5px;
      border-width: 0 8px 48px 8px;
      border-color: transparent transparent #2692D8 transparent;
    }
  }

  a,
  h1,
  h3,
  .overhead {
    font-family: SourceSansPro;
  }

  .overhead,
  h1 {
    text-align: center;
  }

  .overhead {
    //font-size: 18px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.36px;
  }

  h1 {
    margin-bottom: 64px;
    margin-top: 12px;
    font-size: 34px;
  }

  h3 {
    //font-size: 19px;
    font-size: 17px;
    line-height: 1.26;
    text-align: center;
    margin: 0 auto 16px auto;
    max-width: 219px;
  }

  .table {
    margin: 0 auto;
  }

  .left {
    position: relative;
    padding-left: 40px;
    padding-right: 100px;
    padding-bottom: 10px;

    h3 {
      line-height: 1.47;
    }

    &::after {
      position: absolute;
      top: 40px;
      right: 0;
      bottom: 0;
      content: ' ';
      border-right: 1px solid #C8CED9;
    }

    .benefit {
      display: table;
      width: 100%;
      max-width: 342px;
      height: 66px;
      margin: 0 auto;
      position: relative;

      .text {
        display: table-cell;
        vertical-align: middle;
        padding: 0 16px 0 78px;
        font-size: 13px;
      }

      .icon-container {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: #85cee4;
        top: 50%;
        left: 20px;
        margin-top: -21px;

        svg {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -15px 0 0 -15px;
          transform: scale(0.8);
        }
      }
    }
  }

  .right {
    //padding-right: 80px;
    padding-left: 100px;

    .buttons {
      margin-top: 40px;

      a.button {
        font-family: SourceSansPro;
        text-align: center;
        display: block;
        width: 230px;
        margin: 20px auto 0 auto;
        border: none;
        //border-radius: 24px;
        //font-size: 20px;
        font-size: 18px;
        color: #fff;
        background-color: #2e455b;
        -webkit-tap-highlight-color: transparent;
        height: 40px;
        padding-top: 8px;
        line-height: 25px;

        [disabled] {
          background-color: #253e6a;
        }

        :focus:not(:focus-visible) {
          outline: none;
        }

        &:hover {
          background-color: #85cee4;
        }

        &:active {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  @media screen and (max-width: 951px) {
    margin-left: -12px;
    margin-right: -12px;
    padding: 50px 10px 32px 10px;

    .new-label {
      right: 22px;
    }

    h1 {
      //font-size: 33px;
      margin-bottom: 0;
      margin-top: 12px;
      font-size: 31px;
    }

    h3 {
      //font-size: 22px;
      font-size: 18px;
      margin-bottom: 24px;
    }

    .left,
    .right {
      width: 100%;
      padding: 0;
    }

    .left {
      box-sizing: border-box;
      padding: 0 10px 0 10px;


      h3 {
        max-width: 100%;
        margin: 0 0 24px 0;
      }


      &::after {
        border: none;
      }

      $icon-left: 31;

      .benefit-container {
        padding-left: #{$icon-left}px;
      }

      .benefit {
        //margin-bottom: 14px;

        .icon-container {
          //width: 70px;
          //height: 70px;
          //top: 20px;
          left: -5px;

          svg {
            //transform: scale(1.1);
          }
        }

        .text {
          padding-left: 49px;
          //font-size: 16px;
          font-size: 14px;
          line-height: 1.19;
        }
      }
    }

    .right {
      border-left: 0;
      box-sizing: border-box;
      padding: 40px 10px 40px 10px;
      margin-bottom: 40px;
      position: relative;

      &::after {
        content: " ";
        position: absolute;
        right: 20%;
        bottom: 0;
        left: 20%;
        border-right: 0;
        border-bottom: 1px solid #c8ced9;
      }

      .buttons {
        margin-top: 40px;

        a.button {
          width: 100%;
          max-width: 294px;
          height: 48px;
          padding-top: 12px;
        }
      }
    }

    .table-row {
      display: flex;
      flex-direction: column-reverse;

    }

    .table,
    .table-row,
    .table-cell {
      //display: block;
    }
  }

  @media screen and (max-width: 320px) {
    .left {
      .benefit {
        .text {
          //font-size: 14px;
          font-size: 12px;
          line-height: 1;
        }
      }
    }
  }

  @media screen and (max-width: 374px) {
    .right {
      //padding: 40px 20px 0 20px;
    }
  }

  @media screen and (min-width: 1048px) {
    margin-left: -30px;
    margin-right: -30px;
  }

  @media screen and (min-width: 1280px) {
    margin-left: -60px;
    margin-right: -60px;
  }
}

.or-divider {
  // TUICROLLOUTS-627 dismantling for 1.10.0.
  //    Restore for 1.11.0 if 1.10.0 was released

  position: relative;
  max-width: 238px;
  margin: 0 auto 48px auto;

  @include font(20px, 1.4);
  font-weight: bold;
  text-align: center;

  &::before,
  &::after {
    content: " ";
    background: $color-tuic-border-light;
    width: 70px;
    height: 1px;
    position: absolute;
    top: 50%;
    margin-top: -0.5px;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  @media #{$max-screen-tablet-desktop} {
    @include font(18px, 1.33);
  }
}
