$price-font-size: 21px;
$price-sub-font-size: 15px;

.price {
    @include font($price-font-size, 1);
    margin-bottom: 12px;

    + .price {
        margin-top: 0.2em;
    }

    &.inclusive {
      @include font($price-font-size);
      & .price__label {
        font-weight: bold;
        vertical-align: top;
      }
    }
}

.price__unit {
    @include font($price-sub-font-size);
    margin-left: 0.4em;
}

.price__value {
    white-space: nowrap;
}

.price__label {
    @include font($price-sub-font-size);
    margin-right: 0.4em;
}

@media #{$screen-tablet-desktop} {
    $price-font-size: 30px;
    $price-sub-font-size: 18px;

    .price {
        @include font($price-font-size);
    }

    .price__label,
    .price__unit {
        @include font($price-sub-font-size);
    }
}
