
.modal.health {
  margin-top: 50px;
  font-family: SourceSansPro;

  @media #{$screen-tablet-desktop} {
    margin: 16vh auto 0 auto;
  }

  @media #{$screen-tablet-desktop} {
    max-width: 556px;

    .modal-inner-content {
      max-height: 90vh;
    } 
  }
}

.modal-content.health {
  padding: 10px;

  @media #{$screen-tablet-desktop} {
    margin: 0;
    h2 .icon.healthy::before {
          margin-left: 2px;
    }
  }

  @media #{$max-screen-tablet-desktop} {
    h2 .icon.healthy {
      height: 60px;
      &::before {
        padding-left: 8px;
      }
    }
  }

  .modal-close::before {
    font-size: 14px;
    padding: 12px 16px 12px 6px;
  }

  .text {
    padding: 14px 10px 16px 10px;
    font-size: 15px;
    line-height: 1.333em;

    @media #{$screen-tablet-desktop} {
      padding: 6px 15px 16px 15px;
    }

    a {
      font-size: 15px;
      line-height: 20px;
      text-decoration: none;
      font-weight: bold;
      font-style: italic;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .subline {
     padding: 0 10px 14px 10px;
    font-size: 18px;
    line-height: 1.111em;

    @media #{$screen-tablet-desktop} {
      padding: 0 15px 12px 15px;
    }
  }

  ol {
    margin-left: 12px;
    padding: 0 10px 16px 10px;
    font-size: 15px;
    line-height: 1.333em;
    list-style-type: decimal;

    @media #{$screen-tablet-desktop} {
      padding: 0 15px 20px 15px;
    }
  }

  li {
    padding-left: 14px;
    font-style: italic;
    margin-bottom: 8px;

    @media #{$screen-tablet-desktop} {
       margin-bottom: 4px;
    }

    a,
    ul{
      display: none;
    }
  }

}