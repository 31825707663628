.arrival-departure-details {
    .info {
        margin: 0 0 20px 0;

        @media #{$screen-tablet-desktop} {
            margin-top: 10px;
        }
    }
}

.arrival-departure-info {
    margin-bottom: 20px;

    &--header {
        margin-bottom: 20px;
        font-weight: bold;
        line-height: 1.6;
    }

    &--content {
        line-height: 1.6;
    }

    &--airport {
        white-space: nowrap;
    }
}
