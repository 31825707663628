
.arrival-departure-item.services {
    &:hover {
        .divider.triangles::before {
            border-color: transparent transparent transparent $color-tuic-blue-light-hover;
        }

        .divider.triangles::after {
            border-color: transparent $color-tuic-blue-light-hover transparent transparent;
        }
    }
}


.arrival-departure-item.services {
    .divider {
        height: 1px;
        background-color: $color-tuic-blue-info;
        margin: 0 10px;

        &.triangles {
            position: relative;
        }

        &.triangles::before,
        &.triangles::after {
            position: absolute;
            top: -6px;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
        }

        &.triangles::before {
            left: -20px;
            border-width: 6px 0 6px 8px;
            border-color: transparent transparent transparent $color-tuic-blue-white;
        }

        &.triangles::after {
            right: -20px;
            border-width: 6px 8px 6px 0;
            border-color: transparent $color-tuic-blue-white transparent transparent;
        }
    }
}

.train-info-case {
    padding: 10px;

    .top-part {
        display: flex;
        flex-direction: row;
        justify-content: initial;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0 10px 20px 10px;
        font-size: 16px;

        @media screen and (min-width: 360px) {
            justify-content: space-between;
        }

        @media screen and (min-width: 440px) {
            align-items: flex-end;
        }

        > div {
            width: unset;

            @media screen and (min-width: 490px) {
                width: 33%;
            }

            &.only {
                flex: 1 1 auto;
            }
        }



        & svg {
            display: block;
            margin-left: -10px;
            margin-right: 10px;
            width: 75px;
            height: 44px;

            @media screen and (min-width: 440px) {
                width: 114px;
                height: 68px;
            }
        }
        
        .child-small {
            display: block;

            @media screen and (min-width: 360px) {
                display: none;
            }
        }

        .child-default {
            display: none;

            @media screen and (min-width: 360px) {
                display: block;
            }
        }

    }

    .bottom-part-default {
        display: none;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 10px 0 10px;
        font-size: 16px;

        @media screen and (min-width: 360px) {
            display: flex;
        }

        > div {
            width: unset;

            @media screen and (min-width: 490px) {
                width: 33%;
            }
        }

        .db-n-m {
            display: inline-block;

            @media #{$screen-tablet-desktop} {
                display: none;
            }
        }

        .db-n-td {
            display: none;

            @media #{$screen-tablet-desktop} {
                display: inline-block;
            }
        }

    }

    .bottom-part-to-small {
        display: block;
        padding: 10px 10px 0 10px;
        font-size: 16px;

        @media screen and (min-width: 360px) {
            display: none;
        }
    }
}

.flight-info-case {
    padding: 10px;

    .bold {
        font-weight: bold;
    }

    .icon.seat {
        font-size: 40px;
    }

    .phone {
        white-space: nowrap;

        &.call {
            display: none;
        }

        &.text {
            display: inline;
        }
    }

    .line {
        box-sizing: border-box;
        display: table;
        width: 100%;

        &.margin {
            margin: 18px 0;
        }

        &.top {
            .item {
                vertical-align: top;
            }
        }

        .item {
            box-sizing: border-box;
            display: table-cell;
            vertical-align: middle;

            &.airline-logo {
                width: 24%;

                img {
                    max-width: 114px;
                    background-color: white;

                    &.airline-svg {
                        padding: 0 12px;
                        max-width: 90px;
                        height: 68px;
                    }
                }
            }

            &.item-X {
                @include font(16px);
                line-height: 18px;
                width: 20%;
                text-align: right;
                padding-right: 10px;
            }

            &.airplane-icon {
                width: 10%;
                text-align: center;
                margin-top: 6px;

                .icon.airplane {
                    font-size: 38px;
                }
            }

            &.empty {
                width: 10%;
            }

            &.from,
            &.to {
                @include font(26px);
                line-height: 24px;
                letter-spacing: 1px;
                text-align: center;
                width: 23%;

                .time {
                    @include font(15px);
                    line-height: 22px;
                    letter-spacing: 0;
                }
            }

            &.flight-number {
                width: 24%;
                padding-left: 10px;
            }

            &.departure,
            &.arrival {
                width: 23%;
                text-align: center;
            }

            &.item-X,
            &.from,
            &.to,
            &.flight-number,
            &.flight-class,
            &.departure,
            &.arrival {
                .a,
                .b {
                    @include font(16px);
                    line-height: 22px;
                }

                .b {
                    font-weight: bold
                }
            }

            &.item-90 {
                width: 90%;
                padding: 10px;

                .a {
                    @media screen and (max-width: 330px) {
                        padding-bottom: 8px;
                    }
                }

                @media screen and (max-width: 583px) {
                    padding-left: 0;
                }
            }

            &.item-10 {
                width: 10%;
            }
        }
        .wrapper {
          display: flex;
          flex-direction: row;
          padding-left: 10px;
        
          .info-key {
              width: 16%;
              min-width: 80px;
          }

          .info-value {
              width: 84%;
          }
        }
    }

    .share-flight {
      padding-left: 10px;
      margin: -18px 0 18px 0;
      font-weight: bold;

      @media screen and (max-width: 583px) {
        padding-left: 0;
      }
    }
    .non-published .share-flight {
      margin: 0;
      padding-left: 0;
      @media screen and (max-width: 583px) {
        margin: -18px 0 18px 0;
      }
    }

    .mobile {
        .line {
            &.neg-margin {
                margin-top: -14px;
            }

            .item {
                &.item-X {
                    text-align: left;
                    width: 50%;
                }

                &.from,
                &.to {
                    text-align: left;

                    @media screen and (max-width: 320px) {
                        @include font(23px);
                    }
                }

                &.from {
                    width: 28%;
                }

                &.airplane-icon {
                    width: 22%;
                    text-align: left;
                }

                &.to {
                    width: 50%;
                }

                &.flight-number {
                    width: 50%;
                    padding-left: 0;
                }

                &.flight-class {
                    width: 50%;
                    text-align: left;
                    padding-right: 0;
                }

                &.departure,
                &.arrival {
                    width: 50%;
                    text-align: left;
                }

                &.item-X,
                &.flight-number,
                &.flight-class,
                &.departure,
                &.arrival {
                    @media screen and (max-width: 320px) {
                        @include font(13px);
                    }

                    .a,
                    .b {
                        @media screen and (max-width: 320px) {
                            @include font(13px);
                        }
                    }
                }
            }
        }
    } 

    .hint {
        padding: 0 10px 10px 10px;

        a {
            text-decoration: underline;
        }

        @media screen and (max-width: 583px) {
            padding-left: 0;
        }
    }
}
