/**
* TUICUNIT-54
*
*/
.slider-container {
    overflow: hidden;
    position: relative;
    margin-bottom: 12px;

    &.gallery-tile {
        height: 153px;
    }

    &.large-tile {
      height: auto;
    }

    @media #{$screen-desktop} { // min-width: 644px
      &.large-tile {
        height: auto;
        max-height: 283px;
        width: 580px;
        float: left;
        margin-bottom: 0;
        margin-right: 12px;
      }
    }

    * {
        box-sizing: border-box;
        outline: 0;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-slide {
        float: left;
        position: relative;

        .image-comment {
            position: absolute;
            bottom: 5px;
            left: 0;
            width: 100%;
            padding: 10px 12px;
            font-size: 13px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.38;
            letter-spacing: normal;
            overflow: hidden;
            background-color: $color-dark-blue-80;
            color: $color-white;

            @media screen and (max-width: 357px) {
                padding: 8px;
                font-size: 11px;
            }

            @media #{$screen-tablet-desktop} {
                padding: 18px 26px;
            }
        }
    }

    .slick-prev,
    .slick-next {
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 30px;
        height: 30px;
        background-color: $color-white-80;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        cursor: pointer;
        transform: rotate(90deg);
        font-size: 0;
        line-height: 0;
        padding: 0;
        margin: -36px 0 0 0;

        &:before {
            content: '';
        }

        &:hover {
            opacity: 0.75;
        }

        .catalog-tile & {
            margin-top: -25px;
        }

        @media #{$screen-mobile} {
            .catalog-tile & {
                margin-top: -20px;
            }
        }

        @media #{$screen-tablet-desktop} {
            width: 50px;
            height: 50px;
            margin-top: -50px;
            background-size: 24px;

            .catalog-tile & {
                width: 30px;
                height: 30px;
                background-size: 16px;
                margin-top: -25px;
            }
        }
    }

    .slick-prev {
        left: 0;
        background-image: url('../assets/svg/arrow-down.svg');
    }

    .slick-next {
        right: 0;
        background-image: url('../assets/svg/arrow-up.svg');
    }

    .slick-dots {
        text-align: center;

        li {
            display: inline;
            button {
                font-size: 0;
                line-height: 0;
                padding: 0;
                margin: 0 4px;

                border-radius: 100%;
                width: 10px;
                height: 10px;
                background-color: transparent;
                border: 1.6px solid $color-tuic-blue-dark;
            }

            &.slick-active {
                button {
                    background-color: $color-tuic-blue-dark;
                }
            }
        }
    }
}



 @media #{$screen-desktop} {
  .highlight-wrapper:hover {
    background-color: $color-tuic-blue-light-hover;
  
    .slick-arrow {
      opacity: 1;
    }
  }
  .highlight-headline {
     display: none;
  }
}

@media #{$screen-tablet} {
  .highlight-wrapper {
    -webkit-tap-highlight-color: transparent;
    .highlight-headline {
      display: none;
    }

  .slick-arrow {
      opacity: 1;
    }
  }
}

 @media (max-width: 643px) {
  .highlight-tile {
    margin-bottom: 0;
  }

  .tile.highlight-wrapper {
    flex-direction: column;
    padding-bottom: 5px;

    .highlight-headline {
      @include font(24px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 4px 4px 12px 0;

      @media (max-width: 400px) {
        @include font(18px);
      }

      .count {
        @include font(15px);
        display: flex;
        align-items: center;

        @media (max-width: 400px) {
          @include font(12px);
        }
      }
    }

    .wrapper {
      background-color: #b4e1f1;
      @media (max-width: 643px) {
        max-width: 240px;
      }

      @media (max-width: 370px) {
        max-width: 200px;
      }
    }
  }
}

.tile.highlight-wrapper {
  .badge {
    z-index: 110;
  }

  .wrapper .tile__clamp {
    text-align: left;
    height: 3.8em;
    max-height: 3.8em;
    @media (max-width: 643px) {
      padding: 0 10px;
    }
    @media (max-width: 370px) {
      padding: 0 5px;
    }
  }
}

.highlight-tile {
  height: 100%;
  max-height: 230px;

  @media #{$screen-tablet-desktop} {
    height: 272px;
    max-height: unset;
  
    a {
        height: 246px;
    }
  }

  .slick-slider {
    position: relative;
  }

  .slick-slide {
    padding: 0 3px;
    text-align: center;

    @media #{$screen-tablet-desktop} {
      padding: 0;
      text-align: unset;
    }

    img {
      max-width: 240px;

      @media #{$screen-tablet-desktop} {
        max-width: unset;
      }
    }
  }


  .slick-arrow {
    position: absolute;
    top: -2px;
    width: 24px;
    height: 24px;
    margin: 0 0 0 0;
    border-radius: 50%;
    transition: opacity 250ms ease ;
    background-size: 12px;

    @media #{$screen-desktop} {
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }
  }
  @media #{$screen-tablet-desktop} {
    .slick-active {
      z-index: 100;
    }
  }

  .slick-prev,
  .slick-next {
    background-color: rgba($color-tuic-blue-white, 0);
    border-radius: 50%;

    @media #{$screen-desktop} {
      &:hover {
        animation: bgChange 250ms forwards ease-in-out;
      }

      @keyframes bgChange {
        from { background-color: rgba($color-tuic-blue-white, 0); }
        to   { background-color: rgba($color-tuic-blue-white, 1); }
      }
    }

     @media #{$screen-tablet} {
      &:active {
        animation: bgChange 250ms forwards ease-in-out;
      }

      @keyframes bgChange {
        from { background-color: rgba($color-tuic-blue-light-hover, 0); }
        to   { background-color: rgba($color-tuic-blue-light-hover, 1); }
      }
    }
  }

  .slick-prev {
    left: 94px;
    background-position: 50% 57%;
  }

  .slick-next {
    right: 94px;
    background-position: 50% 46%;
  }

  .slick-dots {
    @include font(15px);
    position: absolute;
    bottom: -266px;
    width: 100%;
    height: 20px;
    text-align: center;
  }
}
