
.page.survey {
  font-family: SourceSansPro;
  min-height: 100vh;

  .nobr {
    white-space: nowrap;
  }


  .headline {
    margin: 0 0 8px;

    h1 {
     font-family: SourceSansPro; 
    }
  }

  .double {
    padding-right: 0;
  }

  .intro {
    font-size: 15px;
    line-height: 19px;
  }

  a {
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;
    font-weight: bold;
    font-style: italic;

    &:hover {
      text-decoration: underline;
    }

    &.back.button {
      font-weight: normal;
      font-style: normal;

      @media #{$max-screen-tablet-desktop} {
        margin: 0 8px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.page.survey.error {
  min-height: 20vh;

  .error-message--form {
    font-family: SourceSansPro;
    font-size: 15px;
  }
}

.page.survey {
  h2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 2px;
    font-family: SourceSansPro;
    font-size: 21px;
    line-height: 1.2;

    .icon {
      flex: 1 1 auto;
      height: 64px;
      width: 64px;
      max-width: 64px;
      margin-left: 10px;
      margin-right: 6px;
      font-size: 2.4em;

      @media (min-width: 420px) {
        margin-left: 16px;
      }
    }
    span {
      flex: 1 1 auto;
    }

    .lang {
      margin-right: 0;
      margin-top: -10px;
      text-align: right;
      width: 100%;
       -webkit-tap-highlight-color: transparent;

      @media (min-width: 420px) {
        width: unset;
        margin-right: 26px;
        margin-top: 0;
      }

      .lang-inner {
        padding: 5px 10px 10px 10px;
        font-size: 15px;
      
        &:hover {
          cursor: pointer;
        } 
      }
    }
  }
}

.page.survey .survey-wrapper {
  padding: 24px 0 12px 0;
  margin-left: -12px;

  h2 {
    position: relative;
    min-height: 90px;
    margin: 0;
    padding-bottom: 6px;
    background-color: $color-tuic-blue-light;

    @media (min-width: 420px) {
      min-height: 80px;
      padding-bottom: 0;
    }

    &::after {
      content: '';
      background-color: $color-tuic-blue-light;
      position: absolute;
      height: 12px;
      width: 100%;
      left: 0;
      bottom: -10px;
    }

    .name {
      max-width: 70%;

      @media #{$screen-tablet-desktop} {
        max-width: 400px;
      }
    }

    .lang {
      margin-top: -12px;

      @media (min-width: 420px) {
        margin-top: -6px;
        margin-right: 22px;
      }

      .lang-inner {
        padding: 5px 24px 4px 10px;

        @media (min-width: 420px) {
          padding: 5px 10px 10px 10px;
        }
      }
    }
  }

  .box {
    display: flex;
    position: relative;
    flex-direction: column;
    //min-height: 464px;
    margin-top: 0;
    padding: 24px 24px 10px 24px;
    background-color: $color-white;
    border-radius: 8px;
    font-size: 15px;
    overflow: hidden;

    @media #{$screen-tablet-desktop} {
      padding: 24px 32px 0 32px;
    }

    .hidden {
      display: none;
    }

    .count {
      padding-bottom: 6px;
      line-height: 19px;
    }

    .question {
      font-size: 18px;
      line-height: 24px;

      @media #{$screen-tablet-desktop} {
        font-size: 21px;
        line-height: 25px;
      }

      a {
        font-size: 16px;

        @media #{$screen-tablet-desktop} {
          font-size: 18px;
        }
      }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 14px 0 -8px -8px;
        list-style: initial;
        list-style-position: outside;

        @media #{$screen-tablet-desktop} {
            margin: 14px 0 0 -8px;
        }

        li {
          position: relative;
          width: 100%;
          margin-left: 20px;
          font-size: 8px;

          @media #{$screen-tablet-desktop} {
            width: 29%;
          }

          span {
            display: block;
            position: relative;
            top: 2px;
            min-height: 1.6em;
            font-size: 15px;
            line-height: 15px;
            word-break: break-word;
            hyphens: auto;

            @media #{$screen-tablet-desktop} {
              min-height: 2em;
            }
   
          }
        }
      }
  
    .hint-icon {
      position: relative;
      display: block;
      width: 26px;
      padding: 8px 5px 6px 0;
      color: $color-tuic-blue-dark;

      @media #{$screen-tablet-desktop} {
        display: inline;
        width: 17px;
        padding: 0 24px 4px 4px;
      }
      
      &:hover {
        cursor: pointer;
      }

      svg {
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        fill: $color-tuic-blue-dark;

        @media #{$screen-tablet-desktop} {
          position: absolute;
          display: inline;
          top: 2px;
          width: 22px;
          height: 22px;
        }
      }
    }

    .hint-view {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: calc(100% - 48px);
      padding: 24px 24px 10px 24px;
      background-color: $color-white;
      box-shadow: -1px 0px 4px 2px $color-print-grey-light;
      transform: translate3d(800px, 0 ,0);
      transition: transform 300ms ease-in;
      z-index: 500;


      @media #{$screen-tablet-desktop} {
        width: calc(100% - 64px);
        padding: 24px 32px 0 32px;
      }

      &.show {
        transform: translate3d(0, 0, 0);
      }

      .modal-close::before {
        padding: 20px 24px;
        font-size: 14px;

         @media #{$screen-tablet-desktop} {
          padding: 26px 32px;
        }
      }

      .head {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 0 13px 0;
        font-size: 21px;
        line-height: 25px;

        @media #{$screen-tablet-desktop} {
          padding: 21px 0 13px 0;
        }

        svg {
          width: 30px;
          height: 30px;
          margin-right: 12px;
          fill: $color-tuic-blue-dark;
        }
      }

      .text {
        font-size: 15px;
        line-height: 19px;
      }

    }

    .answer {
      margin: 0 -24px;
      padding: 10px 0 16px 0;
      font-size: 18px;
      line-height: 18px;

      @media #{$screen-tablet-desktop} {
        margin: 0 -33px;
        padding: 20px 0 0 0;
      }

      label {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        min-height: 65px;
        padding: 0 24px;
        z-index: 5;

        &:hover {
          cursor: pointer;
        }

        @media #{$screen-tablet-desktop} {
          align-items: center;
          min-height: 80px;
          padding: 0 32px;
          flex-wrap: nowrap;

          &.input-radio:hover,
          &.input-checkbox:hover {
            background-color: $color-input-radio-active;
            transition: background-color 250ms ease;
          }
        }
      }

      @media #{$screen-tablet-desktop} {
        input[type='radio']:checked + label {
          background-color: $color-input-radio-active;
        }
      }

      input[type='checkbox'],
      input[type='radio'] {
        padding: 0;
        outline: 0;
        opacity: 0;
        appearance: none;
        display: none;
      }

      input[type='radio'] + label::before {
        content: '';
        width: 32px;
        height: 32px;
        min-width: 32px;
        border: #7cb7cb 3px solid;
        border-radius: 50%;
        background-clip: padding-box;

        @media #{$screen-tablet-desktop} {
          width: 16px;
          height: 16px;
          min-width: 16px;
          margin-top: 0;
          border: #7cb7cb 2px solid;
        }
      }

      input[type='radio']:checked + label::before {
        background-color: $color-tuic-blue-dark;
        box-shadow: inset 0 0 0 7px $color-white;

        @media #{$screen-tablet-desktop} {
          box-shadow: inset 0 0 0 4px $color-input-radio-active;
        }
      }

      .input-radio {
        .text {
          margin-left: 13px;
          padding: 2px 0 2px 0;

          @media #{$screen-tablet-desktop} {
            margin-left: 8px;
          }
        }
      }

      input[type='checkbox'] + label::before {
        content: '';
        width: 34px;
        height: 34px;
        min-width: 34px;
        border: $color-tuic-blue-dark 1px solid;
      }

      input[type='checkbox']:checked + label::before {
        content: '\f104';
        background-color: $color-tuic-blue-dark;
        font-family: fontcustom;
        color: $color-white;
        font-size: 16px;
        line-height: 38px;
        text-align: center;
      }

      .input-checkbox {
        font-size: 15px;
        line-height: 19px;
        flex-wrap: nowrap;
        margin: 0 0 8px 0;

        @media #{$screen-tablet-desktop} {
          margin: 0 0 10px 0;
        }

        .text {
          margin-left: 13px;
          padding: 2px 0 2px 0;

          &.w {
            min-width: 80px;
            width: 80px;

            @media #{$screen-tablet-desktop} {
              min-width: 100px;
              width: 10%;
            }
          }
        }
      }

      .wrong-answer {
        width: 100%;
        margin-left: 15px;
        padding: 2px 0 2px 0;
        font-size: 15px;
        line-height: 19px;

        @media #{$screen-tablet-desktop} {
          margin-left: 30px;
        }
      }

      .input-text {
        display: block;
        padding: 0 24px;

        @media #{$screen-tablet-desktop} {
          padding: 0 32px;
        }

        label {
          height: auto;
          min-height: 1em;
          padding: 0;
        }

        textarea {
          width: 100%;
          margin: 10px 0 20px 0;
          padding: 5px;
          outline: none;
          resize: none;
          overflow: auto;
          white-space: normal;
          border: 1px solid #e8ecf2;
          background-color: #e8ecf2;

          &:hover {
            cursor: pointer;
          }

          &:focus {
            border: 1px solid #c8ced9;
            background-color: $color-white;
            cursor: auto;
          }
        }
      }

      .line {
        display: none;

        @media #{$screen-tablet-desktop} {
          position: relative;
          display: block;
          height: 1px;
          bottom: 1px;
          margin: 0 32px;
          background-color: #c8ced9;
          z-index: 1;
        }
      }
    }
  }

  .result {
    padding: 0 0 12px 6px;
    @media #{$screen-tablet-desktop} {
      padding: 8px 8px 0 8px;
    }
  
    .headline {
      font-size: 18px;
      line-height: 24px;

      @media #{$screen-tablet-desktop} {
        font-size: 21px;
        line-height: 25px;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
       
        margin-right: 23px;
        margin-bottom: -2px;
        border-radius: 50%;

        @media #{$screen-tablet-desktop} {
          margin-right: 34px;
          margin-bottom: 0;
        }
      }

      &.ok:before {
        background-color: #69c24c;
      }

      &.no:before,
      &.no.level2:before {
        background-color: #f5a70f;
      }
      &.no.level1:before {
        background-color: #e01c1c;
      }

      &.confirm {
        display: flex;
        svg {
          margin-left: -6px;
          padding-right: 20px;
          
          @media #{$screen-tablet-desktop} {
            margin-left: 0;
            padding-right: 24px;
          }
        }

        &:before {
          display: none;
        }
      }
    }

    ol {
      list-style: decimal;
      list-style-position: outside;
      margin-left: 8px;
      padding-top: 10px;
      padding-bottom: 9px;

      @media #{$screen-tablet-desktop} {
        margin-left: 15px;
        padding-top: 15px;
        padding-bottom: 13px;
      }

      
      li {
        padding-left: 3px;
        padding-bottom: 15px;

        @media #{$screen-tablet-desktop} {
          padding-bottom: 19px;
        }
      }
    }

    .part {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      @media #{$screen-tablet-desktop} {
        justify-content: space-between;
      }

      &.next-line {
        justify-content: flex-start;
      }
      .r-question {
        flex: 1 1 auto;
        width: 100%;
        margin-right: 6px;
        padding-bottom: 6px;
        font-size: 15px;
        line-height: 19px;

        @media #{$screen-tablet-desktop} {
          width: 70%;
          padding-bottom: 2px;
        }

        &.next-line {
          width: 100%;
        }

        ul {
          margin: 6px 0 0 -3px;

          @media #{$screen-tablet-desktop} {
            margin: 11px 0 -15px -3px;
          }

          li {
            width: 100%;
            margin-left: 15px;
            padding-bottom: 10px;
            padding-left: 0;
            
            &:last-child {
              padding-bottom: 2px;
            }

            @media #{$screen-tablet-desktop} {
              padding-bottom: 0;
              width: 28%;

              &:last-child {
                padding-bottom: 0;
              }
            }

            span {
              font-size: 15px;
              line-height: 15px;
              min-height: unset;
              

              @media #{$screen-tablet-desktop} {
                font-size: 13px;
                line-height: 12px;
                min-height: 1.95em;
              }
            }
          }
        }
      }

      .r-answer {
        min-height: 16px;
        padding: 2px 8px 2px 6px;
        font-size: 13px;
        font-style: italic;
        line-height: 15px;
        border: solid 1px $color-tuic-blue-dark;
        margin-right: 5px;
        word-break: break-word;

        @media #{$screen-tablet-desktop} {
          margin-top: 3px;
        }

        &.no,
        &.no.level2 {
          color: $color-white;
          font-weight: bold;
          background-color: #f5a70f;
          border: solid 1px #f5a70f;
        }

        &.no.level1 {
          color: $color-white;
          font-weight: bold;
          background-color: #e01c1c;
          border: solid 1px #e01c1c;
        }

        
      }
    }

    .r-wrong-info {
      width: 100%;
      margin-left: -6px;
      padding-top: 11px;
      padding-bottom: 24px;
      font-size: 15px;
      line-height: 18px;

      @media #{$screen-tablet-desktop} {
        width: 90%;
        margin-left: 0;
        padding-top: 15px;
        padding-top: 16px;
      }
    }

    .wrong-line {
      display: block;
      height: 1px;
      margin: 0 0 14px -6px;
      background-color: #c8ced9;

      @media #{$screen-tablet-desktop} {
        margin: 0 0 8px 0;
      }
    }

    .r-wrong {
      width: 90%;
      padding-top: 6px;
      font-size: 13px;
      font-weight: bold;
      line-height: 15px;
      color: #f5a70f;

      &.level1 {
        color: #e01c1c;;
      }
    }
   
   .timestamp {
      margin: 0 0 0 -6px;

      @media #{$screen-tablet-desktop} {
        margin: 0;
      }
   }

    .line {
      display: none;

      @media #{$screen-tablet-desktop} {
        position: relative;
        display: block;
        height: 2px;
        margin: 23px 0px 0 0px;
        background-color: #c8ced9;
        z-index: 1;
      }
    }
  }



  @media #{$max-screen-tablet-desktop} {
    .navigation {
      display: none;
  
      &.small {
        display: block;
        margin: 0 8px;
        padding-top: 24px;

        .button {
          margin-left: 0;
          font-size: 18px;
          padding: 4px 20px 5px 20px;
          font-family: SourceSansPro;
          text-align: center;
        }

        .not-yet {
          display: none;
        }

        &.confirm {
          padding: 24px 0 24px;
          .text {
            font-size: 18px;
            line-height: 24px;
            padding-left: 16px;
            padding-bottom: 16px;
          }
          .button {
            &:last-of-type {
              margin-top: 16px;
            }
          }

          .loading-indicator {
            margin: 11px auto 10px auto
          }
        }
      }
    }
  }


  @media #{$screen-tablet-desktop} {
    .navigation {
      display: flex;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80px;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding: 0;
      font-size: 18px;

      .button {
        font-size: 18px;
        font-family: SourceSansPro;
      }
      &.result-view {
        .button {
          margin-right: 9px;
        }
      }

      .loading-indicator {
        margin: 7px 65px 8px 0;
      }

      .prev,
      .next {
        display: flex;
        padding: 10px 0;
       
        flex-direction: row;
        align-items: center;
        opacity: 1;
        transition: opacity 150ms ease-in-out;

        &:hover {
          cursor: pointer;
        }

        svg {
          padding-top: 2px;
          padding-left: 2px;
          width: 16px;
        }
      }

      .prev {
         margin-left: -4px;
         svg {
          transform: rotateZ(180deg);
          padding-top: 0;
          padding-bottom: 2px;
        }
      }

      .next {
        margin-right: -4px;
        font-weight: bold;
      }

      .not-yet {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
      }

      &.small {
        display: none;
      }
      
      &.confirm {
        padding: 24px 0 24px;

        > div {
          flex: 1 1 auto;
        }

        .text {
          width: 100%;
          font-size: 21px;
          line-height: 25px;
          text-align: center;
          padding-bottom: 15px;
        }

        .button {
          width: 256px;
          max-width: 256px;
          margin-right: 0;

          &:last-of-type {
            margin: 0 10px 0 0;
          }
        }

        .loading-indicator {
          margin: 7px 65px 8px 100px;
        }
      }
    }
  }
}