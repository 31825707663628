.page-travel-documents {
  &.error {
    min-height: 20vh;

    .error-message--form {
      font-family: SourceSansPro;
      font-size: 15px;
    }
  }
  .headline.l-mod {
    margin-bottom: 20px;
  }

  .text {
    @include font(15px, 19px);
    margin-bottom: 20px;

    &.empty {
      margin-bottom: 40px;
    }

    .accordion {
      margin-top: 20px;
      @include font(13px, 17px);

      &-item {
        border: none;
        padding: 0;

        &:last-child {
          border: none;
        }

        &__header {
          @include font(13px, 1.38);
          font-weight: bold;

          &::before {
            font-size: 12px;
            top: 1px;
            left: 122px;
            font-weight: normal;
          }
        }
      }
    }
  }

  .pdf-list-item {
    cursor: pointer;
    background: $color-tuic-blue-white;
    padding: 16px 20px 14px 30px;
    margin-bottom: 10px;
    align-items: center;
    display: block;
    position: relative;

    @media #{$max-screen-tablet-desktop} {
      padding-left: 20px;
    }

    svg {
      position: absolute;
      top: 50%;
      margin-top: -10px;
      right: 20px;

      @media #{$max-screen-tablet-desktop} {
        top: auto;
        bottom: 16px;
        margin-top: 0;
      }
    }

    .date {
      @media #{$max-screen-tablet-desktop} {
        display: block;
        margin-bottom: 10px;
      }
    }

    .name {
      flex: 1;
      font-weight: bold;
      margin: 0 40px 0 0;

      @media #{$max-screen-tablet-desktop} {
        margin-left: 0;
      }
    }

    .spinner-small {
      position: absolute;
      right: 14px;
      left: auto;
      top: 9px;
    }

    &.loading {
      cursor: not-allowed;
    }

    &:hover {
      background: white;
    }

    &.loading:hover {
      background: $color-tuic-blue-white;
    }

    &.current {
      background: white;
    }

    &.current:hover {
      background: white;
    }
  }

  .button-wrapper {
    text-align: right;
    margin: 30px 0;

    .download-all {
      display: inline-block;
      @include font(15px, 1.33);
      font-weight: bold;
      font-stretch: normal;
      font-style: italic;
      cursor: pointer;
      position: relative;

      &:hover {
        opacity: 0.5;
      }

      svg {
        position: absolute;
        left: -28px;
        top: -2px;
      }

      .spinner-small {
        position: absolute;
        left: -35px;
        top: -7px;
      }

      &.loading {
        cursor: not-allowed;
        opacity: 1;
      }
    }
  }


}
