@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes makeVisible {
  to {
    opacity: 1;
  }
}

.spinner-small {
  &,
  &::after {
    border-radius: 50%;
    width: 26px;
    height: 26px;
  }

  font-size: 10px;
  position: fixed;
  z-index: 11;
  top: calc(50% - 13px);
  left: calc(50% - 13px);
  text-indent: -9999em;
  border-top: 2px solid rgba(46, 69, 91, 0.1);
  border-right: 2px solid rgba(46, 69, 91, 0.1);
  border-bottom: 2px solid rgba(46, 69, 91, 0.1);
  border-left: 2px solid #253e6a;
  transform: translateZ(0);
  opacity: 0;
  animation: spin 1.1s infinite linear,
  makeVisible 100ms linear 0s forwards;
}
