$checkbox-size: 34px;

.form-checkbox-group__item {
    align-items: center;
    background: $color-tuic-blue-white;
    cursor: pointer;
    display: flex;
    margin-bottom: 3px;
    min-height: 22px;
    position: relative;
    vertical-align: middle;
    width: 100%;

    &:hover {
        background-color: $color-tuic-blue-light-hover;
        color: $color-white;
    }

    &.disabled {
        background: $color-tuic-blue-light;
        color: $color-tuic-blue-white;

        &:hover {
            background: $color-tuic-blue-light;
            color: $color-tuic-blue-white;
            cursor: auto;
        }
    }

    &__input {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    &__info {
        display: table-cell;
        padding-right: 12px;
        text-align: right;
    }

    &__indicator {
        align-self: stretch;
        background: $color-tuic-blue-light;
        color: $color-white;
        display: block;
        font-family: fontcustom;
        font-size: 1em;
        line-height: $checkbox-size;
        min-height: $checkbox-size;
        min-width: $checkbox-size;
        text-align: center;
        vertical-align: middle;
        width: $checkbox-size;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &__label {
        @include font(12px, 1.5);
        display: block;
        flex-grow: 2;
        padding: 6px 6px 6px 8px;
        text-align: left;
    }
}

:disabled + .form-checkbox-group__item__indicator {
    border-right: 1px solid $color-tuic-bg;
}

:checked + .form-checkbox-group__item__indicator {
    background: $color-tuic-blue-light-hover;

    &::before {
        content: '\f104';
        left: .5px;
        position: relative;
        top: 1px;
    }
}


@media #{$screen-tablet-desktop} {
    .form-checkbox-group__item__label {
        @include font(15px, 1.5);
    }
}
