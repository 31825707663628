.survey-detail-page {
  position: relative;
  left: -12px;
  top: -40px;
  width: 100vw;

  @media #{$screen-tablet-desktop} {
    position: static;
    left: auto;
    top: auto;
    width: auto;
  }
}

.main-app.without-header .survey-detail-page {
  top: auto;
}