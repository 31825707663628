.loading-indicator {
    margin: 8em auto;
    font-size: 6px;
    position: relative;

    > span,
    &::before,
    &::after {
        background: $color-tuic-blue-dark;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);

        -webkit-animation: loading-busy 1.8s ease-in-out 0.16s infinite both;
        -moz-animation: loading-busy 1.8s ease-in-out 0.16s infinite both;
        -o-animation: loading-busy 1.8s ease-in-out 0.16s infinite both;
        animation: loading-busy 1.8s ease-in-out 0.16s infinite both;
    }

    &::before {
        left: -3.5em;
        -webkit-animation: loading-busy 1.8s ease-in-out 0s infinite both;
        -moz-animation: loading-busy 1.8s ease-in-out 0s infinite both;
        -o-animation: loading-busy 1.8s ease-in-out 0s infinite both;
        animation: loading-busy 1.8s ease-in-out 0s infinite both;
    }

    &::after {
        left: 3.5em;
        -webkit-animation: loading-busy 1.8s ease-in-out 0.32s infinite both;
        -moz-animation: loading-busy 1.8s ease-in-out 0.32s infinite both;
        -o-animation: loading-busy 1.8s ease-in-out 0.32s infinite both;
        animation: loading-busy 1.8s ease-in-out 0.32s infinite both;
    }
}

.loading-indicator,
.loading-indicator > span,
.loading-indicator::before,
.loading-indicator::after {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    width: 2.5em;
    height: 2.5em;
}
