$dynamic-font-size: #{'min(4.3vw, 21px);'};

.page.arrival-departure {

    &.empty {
        .l-mod {
            margin-bottom: 20px;
        }
    }

    .no-arrival-departure {
        margin-bottom: 20px;
        font-size: 15px;

        @media #{$screen-tablet-desktop} {
            font-size: 18px;
        }

        a {
            display: inline-block;
            margin-top: 10px;
            text-decoration: underline;
        }
    }

    .one-empty {
        .no-arrival-departure {
            font-size: 13px;
        }
        .arrival-departure-items {
            margin-right: -12px;
            margin-left: -12px;
        }
    }


    .contact-block h2,
    h2.contact-block-headline {
        @include font(22px, 24px);
        font-weight: bold;
    }

    .contact-block {
        margin-bottom: 32px;

        h2 {
            margin: 0;
        }

        .phone-mail-block {
            margin: 0 0 0 -8px;
            padding: 4px 0 6px 0;

            a {
                display: flex;
                align-items: center;
            }

            .icon {
                display: inline-flex;
                font-size: 48px;
                line-height: 1;
            }

            .mail {
                margin-left: -10px;
            }

            .text {
                @include font(28px, 34px);
                text-decoration: none;
            }
        }

        .opening-times {
            @include font(16px);
        }

        @media #{$screen-mobile} {
            h2 {
                @include font(18px, 20px);
            }

            .phone-mail-block {
                .iconholder {
                    .icon {
                        font-size: 40px;
                    }
                }

                .text {
                    @include font(24px, 30px);
                }
            }

            .opening-times {
                @include font(13px);
            }
        }
    }

    .infobox-container {
        margin-left: -12px;
        margin-right: -24px;
    }

    .sleep-and-park-teaser {
      position: relative;
      height: 107px;
      padding: 24px 34px;
      color: $color-white;
      overflow: hidden;

      background: linear-gradient(
        90deg,
          rgb(21, 45, 89) 0%,
          rgba(21, 45, 89, 0.938) 19%,
          rgba(21, 45, 89, 0.841) 34%,
          rgba(21, 45, 89, 0.732) 47%,
          rgba(21, 45, 89, 0.578) 56.5%,
          rgba(21, 45, 89, 0.554) 65%,
          rgba(21, 45, 89, 0.534) 69%,
          rgba(21, 45, 89, 0.176) 73%,
          rgba(21, 45, 89, 0.106) 77%,
          rgba(0, 24, 68, 0.075) 80.2%,
          rgba(0, 24, 68, 0.042) 86.1%,
          rgba(0, 24, 68, 0.021) 91%,
          rgba(0, 24, 68, 0.008) 95.2%,
          rgba(0, 24, 68, 0.002) 98.2%,
          rgba(0, 24, 68, 0) 100%
      );

      p {
        margin: 6px 0 16px 0;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        height: 155px;
        width: unset;
        z-index: -1;
      }

      &::before {
        bottom: auto;
        height: 4px;
        top: -4px;
      }

      &::after {
        display: none;
      }
    }

    .sleep-and-park-teaser__title {
      @include font(21px, 1.14);
      font-size: $dynamic-font-size;
      padding: 4px 173px 0 0;
      position: relative;
    }

    .faq-list {
        margin: 40px 0 0 0;
        padding: 0 12px 0 24px;

        h2.headline {
            font-weight: bold;
            margin: 30px 14px 20px 14px;
        }

        h2.accordion-item__header {
            padding-right: 24px;
        }

        a {
            text-decoration: underline;
        }

        ul {
          list-style: disc;
          padding: 12px 20px 12px 15px;
          margin: 0;
        }
    }

    .arrival-departure-items {
        position: relative;

        &.train-i {
            padding-bottom: 12px;
            font-size: 13px;

            @media #{$screen-tablet-desktop} {
                padding-top: 8px;
                font-size: 15px;
            }

        }

        h2.headline {
            margin: 0 0 12px 34px;

            // Bubbleline BUBBLE
            &::after {
                display: block;
                content: '';
                position: absolute;
                left: 10px;
                top: 4px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: $color-tuic-blue-dark;
                border: 2px solid  $color-tuic-bg;
                z-index: 1;
            }
        }

        .arrival-placeholder {
            margin: 12px 0 0 34px;
            position: relative;
            // Bubbleline BUBBLE
            &::after {
                display: block;
                content: '';
                position: absolute;
                left: -24px;
                bottom: 5px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: $color-tuic-blue-dark;
                border: 2px solid  $color-tuic-bg;
                z-index: 1;
            }
        }

        .arrival-departure-list {
            margin-right: -12px;
        }
    }

    @media #{$max-screen-tablet-desktop} {
        .faq-list {
            margin-right: 28px;
        }

        .arrival-departure-items {
          .arrival-departure-list {
              margin-right: 0;
          }
        }

        .sleep-and-park-teaser {
          background: rgba(38, 62, 106, 0.7);
        }

        .sleep-and-park-teaser__title {
          @include font(16px, 1.14);
          font-size: $dynamic-font-size;
          padding: 4px calc(30vw - 136px) 0 0;
        }
    }
}
