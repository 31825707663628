.list {
    list-style: none;
    padding: 0;
    margin: 0 -12px;

    &::before {
        content: '';
        display: table;
    }
}

.list__item {
    position: relative;
}

.list__item__link {
    display: block;
    padding: 12px;
    margin-top: 12px;
    min-height: 46px;
    color: inherit;
    text-decoration: none;
    background: $color-tuic-blue-white;

    &.disabled {
        @extend %disabled;
        background: $color-tuic-blue-light;
        color: $color-tuic-blue-white;
    }

    &.has-badge {
        margin-top: 30px;
    }

    &:hover {
        color: $color-white;
        background-color: $color-tuic-blue-light-hover;
    }

    
}

.list__item__headline {
    @include font(15px);
}

.list__item__copy {
    padding-top: 2px;
}

@media #{$screen-tablet-desktop} {
    .list__item__headline {
        @include font(21px);
    }

    .list__item__link.has-badge {
        margin-top: 12px;
        padding-top: 45px;
    }
}

.bullet-list {
    list-style-type: disc;
    margin: 14px 0 20px 0;

    >li {
        margin-left: 16px;
    }
}
